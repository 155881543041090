<template>
    <div>
        <!-- delete -->
        <delete-dialog
            ref="deleteDialog"
            @on-delete="getDoubleVisits()"
            success-message='Data is deleted'
        >
            Are you sure you want to <span class="red--text">delete</span> this Double visit report?
        </delete-dialog>

        <v-btn 
            v-if="isSuperAdmin || permissionsUser.WeeklyReports === roleName.action"
            small
            class="mx-2 mb-2"
            color="light-blue white--text"
            :to="{
                name: 'double-visit-report'
            }"
        >
            <v-icon class="me-2"> mdi-plus-circle-outline </v-icon>
            Add new report 
        </v-btn>
        <!-- filter -->
        <v-form @submit.prevent="isFiltered = true; submit()">
            <v-row class="mb-1">
                <v-col md="2" sm="3" cols="6" class="pe-1 pb-1">
                    <v-autocomplete
                        v-model="employeeId"
                        :items="medicalReps.filter(c => c.employeeType  !== userType.medicalRep)"
                        item-value="id"
                        item-text="name"
                        :loading="submitLoading"
                        :disabled="loading"
                        label="Employee"
                        outlined
                        dense
                        hide-details
                    />
                </v-col>

                <!-- medicalRepId -->
                <v-col md="2" sm="3" cols="6" class="px-1 pb-1">
                    <v-autocomplete
                        v-model="medicalRepId"
                        :items="medicalReps.filter(c => c.employeeType  === userType.medicalRep)"
                        item-value="id"
                        item-text="name"
                        :loading="submitLoading"
                        :disabled="loading"
                        label="Medical rep"
                        outlined
                        dense
                        hide-details
                    />
                </v-col>

                <!-- start date -->
                <v-col md="2" sm="3" cols="6" class="px-1 pb-1">
                    <bee-date-picker
                        v-model="startDate"
                        input-label="Start date"
                        elevation="2"
                        menu-picker
                        dense
                        hide-details
                        :input-props="{
                            outlined: true,
                            dense: true,
                            clearable: true,
                        }"
                        :loading="loading"
                        :disabled="loading"
                    ></bee-date-picker>
                </v-col>

                <!-- end date -->
                <v-col md="2" sm="3" cols="6" class=" ps-1 pb-1">
                    <bee-date-picker
                        v-model="endDate"
                        input-label="End date"
                        elevation="2"
                        menu-picker
                        dense
                        hide-details
                        :input-props="{
                            outlined: true,
                            dense: true,
                            clearable: true,
                        }"
                    ></bee-date-picker>
                </v-col>

                <v-spacer />

                <v-col cols="auto" align-self="center" class="d-flex justify-end px-1 pb-1">
                    <v-btn
                        color="success white--text"
                        small
                        :disabled="loading || isAllFieldsEmpty"
                        class="px-sm-4 mx-2"
                        @click="getDoubleVisits()"
                    >                        
                        <v-icon> mdi-magnify </v-icon>
                        Search
                    </v-btn>

                    <v-btn
                        color="alert-color white--text"
                        small
                        :loading="loading"
                        :disabled="!isFiltered"
                        class="px-sm-8"
                        @click="reset(); getDoubleVisits()"
                    >                        
                        Reset
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
        
        <!-- table -->
        <bee-handy-table
            :headers="headers"
            :items="doubleVisits"
            :loading="loading"
            dense
            fixed-header
            :height="$vuetify.breakpoint.height - 200"
            pagination-on-scroll
            zebra
        >
             <!-- actions -->
            <template v-slot:item.actions="{ item }">
                <tooltip text="View Report" top>
                    <!-- v-if="permissionsUser.WeeklyReports !== roleName.action" -->
                    <v-btn
                        icon
                        small
                        :color="isSuperAdmin || permissionsUser.WeeklyReports !== roleName.action  ? 'success' : 'edit-color'"
                        :to="{
                            name: 'double-visit-report',
                            params: {
                                id: item.id
                            },
                            query: {
                                from: 'all',
                                preview: true
                            }
                        }"
                    >
                        <v-icon>
                           mdi-eye-outline
                        </v-icon>
                    </v-btn>
                </tooltip>
                    
                <tooltip
                    v-if="isSuperAdmin || permissionsUser.WeeklyReports === roleName.action"
                    text="Delete report"
                    top
                >
                    <v-btn
                        icon
                        small
                        @click="$refs.deleteDialog.activeDeleteDialog(item.id, 'doubleVisit')"
                        color="alert-color"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </tooltip>
            </template>
        </bee-handy-table>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { roleName, userType } from '@/helpers/enums'
import Tooltip from '@/components/Tooltip.vue'
import DeleteDialog from '@/components/DeleteDialog'
import moment from 'moment'
export default {
    components: {
        Tooltip,
        DeleteDialog
    },

    data() {
        return {
            startDate: null,
            endDate: null,
            roleName,
            loading: false,
            submitLoading: false,
            medicalRepId: null,
            employeeId: null,
            userType,
            isFiltered: false
        }
    },

    computed: {
        headers() {
            const headers = [
                {
                    text: 'Date',
                    name: 'date',
                    value: 'date',
                    setValueExpr: val => this.moment(val).format('YYYY-MM-DD')
                },
                // for role more than team lader
                {
                    text: 'Employee name',
                    name: 'SupervisorName',
                    value: 'employeeId',
                    setValueExpr: val => this.getMedicalRepById(val)?.name
                },
                {
                    text: 'Medical rep',
                    name: 'medicalRep',
                    value: 'medicalRepId',
                    setValueExpr: val => this.getMedicalRepById(val)?.name
                },
                {
                    text: 'Actions',
                    name: 'actions',
                    value: 'actions'
                }
            ]
            return headers
        },

        isAllFieldsEmpty() {
            return !this.startDate && !this.endDate && !this.employeeId && !this.medicalRepId;
        },

        ...mapState({
            permissionsUser: state => state.auth.youPermissions,
            doubleVisits: state => state.doubleVisit.doubleVisits,
            medicalReps: state => state.medicalreps.medicalreps,
            isSuperAdmin: state => state.auth.isSuperAdmin,
        }),

        ...mapGetters({
            getMedicalRepById: 'medicalreps/getMedicalRepById'
        })
    },

    methods: {
        moment,
        getDoubleVisits() {
            this.loading = true
            this.$store.dispatch('doubleVisit/fetchAll', { 
                medicalRepId: this.medicalRepId, 
                employeeId: this.employeeId,
                fromDate: this.startDate, 
                toDate: this.endDate 
            }).finally(() => {
                this.loading = false
            })
        },
        reset() {
            this.medicalRepId = null
            this.employeeId = null
            this.startDate = null
            this.endDate = null
            this.isFiltered = false;
        }
    },

    created() {
        Promise.all([
            !this.medicalReps.length ? this.$store.dispatch('medicalreps/fetchAll', true) : null,

            this.getDoubleVisits()
        ])
    }
}
</script>
