<template>
    <div>
        <div class="d-flex align-center">
            <!-- Add new one -->
            <v-btn
                v-if="isSuperAdmin || permissionsUser.Regions === roleName.action"
            	small
            	color="light-blue white--text"
            	@click="resetInputs(); dialog = true;"
                :disabled="!isSuperAdmin && tab === 0 || !isSuperAdmin && tab === 2"
            >
                <v-icon class="me-2">
                    mdi-plus-circle-outline
                </v-icon>
                add {{currentTabLabel}}
            </v-btn>
        </div>
        <!-- region dialog -->
        <v-dialog
            v-model="dialog"
            transition="dialog-transition"
            persistent
            max-width="350"
        >
            <v-form @submit.prevent="submit" ref="form" :disabled="submitLoading">
                <v-card>
                    <div class="d-flex justify-end py-1 background-title-card ">
                        <v-card-title class="text-uppercase text-subtitle-1 flex-grow-1 justify-center pa-0 white--text">
                            {{ title = (!editItemId ? 'add' : 'update') + ' ' + currentTabLabel }}
                        </v-card-title>

                        <!-- close button -->
                        <v-btn icon @click="dialog = false; $refs.form.resetValidation()">
                            <v-icon color="white">
                                mdi-close-circle-outline
                            </v-icon>
                        </v-btn>
                    </div>
                    <v-card-text class="pt-2 px-3 pb-0">
                        <v-row dense class="ma-0">
                            <!-- Name -->
                            <v-col cols="12">
                                <v-text-field
                                    v-model="dialogData.name"
                                    label="Name"
                                    clearable
                                    :rules="rules.required"
                                    required
                                    outlined
                                    dense
                                    hide-details="auto"
                                    autofocus
                                ></v-text-field>
                            </v-col>

                            <!-- City -->
                            <v-col v-if="tab !== managementType.city" cols="12">
                                <v-autocomplete
                                    v-model="dialogData.cityId"
                                    label="City"
                                    clearable
                                    :items="cities"
                                    item-text="name"
                                    item-value="id"
                                    :rules="rules.required"
                                    required
                                    outlined
                                    dense
                                    hide-details="auto"
                                    :multiple="tab === managementType.sector"
                                    @change="tab === managementType.sector ? fetchRegionsDueDialogCities() : null"
                                ></v-autocomplete>
                            </v-col>
                            
                            <!-- Regions in sector -->
                            <v-col v-if="tab === managementType.sector" cols="12">
                                <v-autocomplete
                                    v-model="dialogData.regionIds"
                                    label="Regions in Area"
                                    clearable
                                    :loading="regionsDialogLoading"
                                    :disabled="
                                        (Array.isArray(dialogData.cityId) && !dialogData.cityId.length) || regionsDialogLoading
                                    "
                                    :items="regionsDueCities"
                                    item-text="name"
                                    item-value="id"
                                    multiple
                                    :rules="rules.required"
                                    required
                                    outlined
                                    dense
                                    hide-details="auto"
                                >
                                    <template v-slot:prepend-item v-if="regionsDueCities.length">
                                        <v-list-item ripple @click="toggle">
                                            <v-list-item-action>
                                                <v-icon>
                                                    {{ icon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Select All
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0" class="me-1">
                                            {{ item.name }}
                                        </span>
                                        <span v-if="index === 1" class="grey--text text-caption">
                                            (+{{ dialogData.regionIds.length - 1 }} others)
                                        </span>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="justify-end">
                        <v-btn
                            type="submit"
                            :loading="submitLoading"
                            color="success white--text"
                            text
                        >
                            save
                        </v-btn>
                        <v-btn
                            @click="dialog = false"
                            text
                        >
                            cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <!-- delete dialog declared here to use it as ref -->
        <delete-dialog
            ref="deleteDialog"
            @on-delete="updateData"
            success-message='Data is deleted'
        >
            Are you sure you want to <span class="red--text">delete</span> this {{ currentTabLabel}}?
        </delete-dialog>

        <!-- tabs -->
        <v-tabs v-model="tab">
            <v-tab v-for="title in tabs.titles" :key="title" class="text-capitalize">
                {{ title }}
            </v-tab>
            <v-tabs-slider color="primary"></v-tabs-slider>
        </v-tabs>
        <v-divider class="mb-2"/>
        
        <!-- city filter -->
        <v-row v-if="tab === managementType.region" no-gutters class="ma-0 mb-2">
            <v-col sm="auto" cols="12">
                <v-autocomplete
                    v-model="cityId"
                    label="City"
                    :items="cities"
                    item-text="name"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    clearable
                    :loading="regionsLoading"
                    @change="fetchRegionsDueCity"
                ></v-autocomplete>
            </v-col>
        </v-row>

        <!-- table -->
        <bee-handy-table
            :headers="headers"
            :items="!loading && !regionsLoading ? [cities, regions, sectorCities][tab] : []"
            pagination-on-scroll
            :items-per-page="20"
            :data-table-props="
                tab === managementType.region
                ? { noDataText: cityId == null ? 'Please select a city.' : 'No regions in this city.' }
                : undefined
            "
            dense
            zebra
            :loading="loading || regionsLoading"
            fixed-header
            :height="$vuetify.breakpoint.height - (tab === managementType.region ? 188 : 146)"
        >
            <template v-slot:item.action="{ item }">
                <div class="d-flex justify-center">
                    <!-- city -->
                    <tooltip v-if="tab === managementType.city" text="show regions" top>
                        <v-btn
                        	icon
                        	color="success"
                        	small
                        	@click="
                                tab = managementType.region;
                                $nextTick(() => { cityId = item.id; fetchRegionsDueCity() })
                            "
                        >
                            <v-icon>mdi-eye</v-icon>
                        </v-btn>
                    </tooltip>

                    <!-- edit -->
                    <tooltip :text="'Edit ' + currentTabLabel" top>
                        <v-btn
                        	icon
                        	@click="editItem(item.id)"
                        	color="edit-color"
                        	small
                            class="mx-2"
                        >   
                            
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </tooltip>
                    <!-- delete -->
                    <tooltip :text="'Delete ' + currentTabLabel" top>
                        <v-btn
                            icon
                            small
                            @click="
                                tab === managementType.region
                                ? (() => {
                                    $refs.deleteDialog.activeDeleteDialog(item.id, 'regions'); 
                                    $refs.deleteDialog.setAdditionalData({ cityId: item.cityId })
                                })()
                                : tab !== managementType.city ? $refs.deleteDialog.activeDeleteDialog(item.id, 'sectors') : $refs.deleteDialog.activeDeleteDialog(item.id, 'cities')"
                            color="alert-color"
                        >
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </tooltip>
                </div>
            </template>
        </bee-handy-table>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Tooltip from '@/components/Tooltip'
import DeleteDialog from '@/components/DeleteDialog';
import rules from '@/validation rules';
import { roleName } from '@/helpers/enums';

export default {
    name: 'Regions',

    components: {
        Tooltip,
        DeleteDialog
    },

    data: () => ({
        // helpers
        tab: 0,
        roleName,
        tabs:{
            titles: ['Cities', 'Regions', 'Area'],
            labels: ['city', 'region', 'Area']
        },
        loading: false,
        regionsLoading: false,

        // filter
        cityId: null,

        // region dialog data
        editItemId: null,
        managementType: {
            city: 0,
            region: 1,
            sector: 2,
        },

        regionsDialogLoading: false,
        regionsDueCities: [],
        
        submitLoading: false,
        dialog: null,

        rules,
        
        dialogData: {
            name: null,
            regionIds: [],
            cityId: [],
        },
    }),

    watch: {
        tab(newVal) {
            if (newVal === this.managementType.region) {
                this.cityId = null;
                this.$store.dispatch('regions/clearRegions');
            }
        }
    },

    computed: {
        ...mapState({
            sectors: state => state.sectors.sectors,
            regions: state => state.regions.regions,
            cities: state => state.cities.cities,
            permissionsUser: state => state.auth.youPermissions,
            isSuperAdmin: state => state.auth.isSuperAdmin,
            sectorCities: state => state.sectors.sectorCities.sectors
        }),

        ...mapGetters({
            getCityById: 'cities/getCityById',
            getCityByName: 'cities/getCityByName',
            getRegionById: 'regions/getRegionById',
            getSectorById: 'sectors/getSectorById',
            getCityNameBySectorId: 'sectors/getCityNameBySectorId',
            getSectorCitiesById: 'sectors/getSectorCitiesById'
        }),

        currentTabLabel() {
            return this.tabs.labels[this.tab];
        },

        // region dialog
        icon () {
            const selectedAllSectors = this.dialogData.regionIds.length === this.regionsDueCities.length
            if (selectedAllSectors) return 'mdi-close-box';
            if (this.dialogData.regionIds.length > 0 && !selectedAllSectors) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline';
        },

        headers() {
            const headers = [
                { 
                    text: 'Name', 
                    value: 'name',
                    class: 'text-body-2 font-weight-bold',
                }
            ]
            if (this.isSuperAdmin || this.permissionsUser.Regions === roleName.action) {
                headers.push(
                    {
                        text: 'Actions',
                        name: 'action',
                        value: 'action',
                        align: 'center',
                        class: 'text-body-2 font-weight-bold',
                        sortable: false,
                    }
                )
            }

            return headers
        }
    },

    methods: {
        fetchRegionsDueCity() {
            if (this.cityId) {
                this.regionsLoading = true;
                return this.$store.dispatch('regions/fetchAll', { cityId: this.cityId }).finally(() => {
                    this.regionsLoading = false;
                });
            }
        },

        updateData() {
            let promise;
            switch (this.tab) {
                case 0: promise = this.$store.dispatch('cities/fetchCityMine'); break;
                case 1: promise = this.fetchRegionsDueCity(); break;
                case 2: promise = Promise.all([
                    this.$store.dispatch('cities/fetchCityMine').then((data) => {
                        this.$store.dispatch('sectors/fetchSectorCities', { cities: data })
                    })
                ]); break;
                default: return;
            }
            this.loading = true;
            promise.finally(() => { this.loading = false; })
        },

        // region dialog
        fetchRegionsDueDialogCities() {
            if (Array.isArray(this.dialogData.cityId) && this.dialogData.cityId.length){
                this.regionsDialogLoading = true;
                this.regionsDueCities = [];
                this.dialogData.cityId.forEach(cityId => {
                    this.$store.dispatch('regions/fetchAll', { cityId }).then((regions) => {
                        this.regionsDueCities.push(...regions);
                    })
                    .finally(() => {
                        this.regionsDialogLoading = false;
                    });
                });
            }
        },

        resetInputs(){
            if (this.$refs.form) this.$refs.form.resetValidation();

            this.editItemId = null;
            this.regionsDueCities = [];
            
            this.dialogData.name = null;
            this.dialogData.cityId = this.tab === this.managementType.region ? this.cityId : [];
            this.dialogData.regionIds = [];
        },

        editItem(id){
            this.editItemId = id;

            if (this.tab === this.managementType.city){
                this.dialogData.name = this.getCityById(id).name;
            }
            else if (this.tab === this.managementType.region){
                this.dialogData.name = this.getRegionById(id).name;
                this.dialogData.cityId = this.getCityById(this.cityId).id;
            }
            else if (this.tab === this.managementType.sector){
                var cityNames = []
                this.dialogData.name = this.getSectorCitiesById(id)?.name;
                this.loading = true
                this.$store.dispatch('sectors/fetchCitiesInSector', { id: id }).then((data) => {
                    this.dialogData.cityId = data.map(c => c.id);
                    this.fetchRegionsDueDialogCities();
                }).finally(() => {
                    this.loading = false
                })
                // console.log(cityNames)
                // this.dialogData.cityId = data;
                // cityNames.forEach(cityName => {
                //     this.dialogData.cityId.push(data);
                // });
                // console.log(cityNames)
                
                this.dialogData.regionIds = this.getSectorCitiesById(id)?.regionIds;
            }
            this.dialog = true;
        },

        submit(){
            if (this.$refs.form.validate()){
                let moduleType, msgType;
                switch (this.tab) {
                    case this.managementType.city: moduleType = 'cities'; msgType = 'City'; break;
                    case this.managementType.region: moduleType = 'regions'; msgType = 'Region'; break;
                    case this.managementType.sector: moduleType = 'sectors'; msgType = 'Sector'; break;
                }

                // this.submitLoading = true;
                const actionName = !this.editItemId ? 'create' : 'update';
                this.$store.dispatch(`${moduleType}/${actionName}`, {
                    id: this.editItemId,
                    name: this.dialogData.name,
                    cityId: this.dialogData.cityId, // for regions
                    regionIds: this.dialogData.regionIds // for sectors
                })
                .then(() => {
                    const message =
                        this.currentTabLabel.charAt(0).toLocaleUpperCase() +
                        this.currentTabLabel.slice(1) +
                        ' is ' + (actionName === 'create' ? 'created' : 'edited');
                    this.$eventBus.$emit('show-snackbar', message, 'info');
                    this.dialog = false
                    this.resetInputs();
                    if (this.cityId !== null) {
                        this.updateData()
                    } 
                })
                .finally(() => {
                    this.submitLoading = false;
                })
            }
        },

        toggle () {
            this.$nextTick(() => {
                if (this.dialogData.regionIds.length === this.regionsDueCities.length) this.dialogData.regionIds = [];
                else this.dialogData.regionIds = this.regionsDueCities.map(c => c.id);
            })
        }
    },

    mounted(){
        this.loading = true;
        Promise.all([
            !this.cities.length ? this.$store.dispatch('cities/fetchCityMine').then((data) => {
                this.$store.dispatch('sectors/fetchSectorCities', { cities: data.map(c => c.id) })
            }) : null,
        ]).finally(() => {
            this.loading = false;
        })
        this.$store.dispatch('regions/clearRegions');
    },

    metaInfo: {
        title: 'Regions Management',
        titleTemplate: '%s | Octopharma'
    }
}
</script>