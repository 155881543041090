<template>
    <div>
        <div class="d-flex flex-wrap justify-sm-space-between justify-end align-center">
            <div class="flex-sm-grow-0 flex-grow-1 order-sm-0 order-1">
                <v-tabs v-model="tab" height="40" grow @change="updateTableDueTab()">
                    <v-tab
                        v-for="(item, index) in types"
                        :key="index"
                        :disabled="loading"
                        class="text-capitalize"
                    >
                        {{ item.name }}
                    </v-tab>
                    <v-tabs-slider color="primary"></v-tabs-slider>
                </v-tabs>
                <v-divider class="mx-1"/>
            </div>
            <v-btn
                v-if="isSuperAdmin || permissionsUser.MaterialTransactions !== roleName.nothing"
                small
                :to="{ name: 'supervisor-remaining-samples' }"
                color="light-blue white--text"
            >
                {{$t('JournalItems.remaining-samples')}}
            </v-btn>
        </div>

        <!-- filter -->
		<v-row class="my-2" dense>
			<v-col cols="12" sm="6" md="3">
				<bee-date-input
					v-model="date"
					:label="$t('inputs.date')"
					input-format-order="DD-MM-YYYY"
					dense
                    :disabled="loading"
					append-icon="mdi-menu-right"
					prepend-icon="mdi-menu-left"
                    hide-details
					@click:append="
                        needToResetShownRows = true;
                        date = moment(date).add(1, 'day').format('YYYY-MM-DD');
                        getJournal();
                    "
					@click:prepend="
                        needToResetShownRows = true;
                        date = moment(date).subtract(1, 'day').format('YYYY-MM-DD');
                        getJournal();
                    "
				></bee-date-input>
			</v-col>
		</v-row>
        
        <bee-handy-smart-table
            v-model="tableInputs"
            ref="table"
            :items="[]"
            :headers="headers"
            :items-per-page="50"
            :loading="loading || submitLoading"
            :show-add-row="permissionsUser.GenerateMaterials !== roleName.read"
            dense
            zebra
            hot-inputs
            hide-inputs-details
            :row-highlight-expression="item => item.type === 0"
			row-highlight-expression-class="orange lighten-4"
            fixed-header
            :height="$vuetify.breakpoint.height - ($vuetify.breakpoint.smAndUp ? 190 : 220)"
            :options-scroll.sync="scrollOptions"
            pagination-on-scroll
            pagination-on-scroll-server-items
            :server-items-length="serverItemsLength"
            :disable-input-fields="submitLoading || loading"
            @keypress.enter="addItem"
            @sheet-add-clicked="addItem"
            @pagination-on-scroll:reach-last-row="getJournal"
        >
            <!--accounts -->
            <template v-slot:input.accountId="{ on ,attr }">
                <!-- tab === 0 ? accounts.filter(c => c.id === employeeId) : accounts -->
                <v-autocomplete
                    :items="tab === 0 ? [currentUserData] : allAccountsBrief"
                    item-value="accountId"
                    item-text="name"
                    v-on="on"   
                    v-bind="attr"
                />
            </template>

            <!-- materialId -->
            <template v-slot:input.materialId="{ on ,attr }">
                <v-autocomplete
                    :items="materials"
                    item-value="id"
                    item-text="name"
                    v-on="on"
                    v-bind="attr"
                />
            </template>

            <!-- date -->
            <template v-slot:input.date="{ on ,attr }">
                <bee-date-input 
                    dense
                    v-on="on"
                    v-bind="attr"
                    label=" "
                ></bee-date-input>
            </template>

            <template v-slot:no-input.actions>
                <tooltip text="Add transaction" right>  
                    <v-btn
                    	color="success"
                    	small
                    	icon
                    	:disabled="loading"
                    	@click="addItem"
                    >
                        <v-icon>mdi-check-circle-outline</v-icon>
                    </v-btn>
                </tooltip>
            </template>
        </bee-handy-smart-table>
    </div>
</template>

<script>
import rules from '@/validation rules'
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import Tooltip from '@/components/Tooltip.vue'
import { roleName } from '@/helpers/enums'
export default {
    name: 'JournalItems',
    components: { Tooltip },
   
    data() {
        return {
            roleName,
            tab: 0,
            loading: true,
            submitLoading: false,
            tableInputs: {},
            rules,
            date: moment().format('YYYY-MM-DD'),

            briefAccounts: [
                [], // for staff
                [], // for customers
                [], // for external
            ],
            
            // pagination
            scrollOptions: {},
            serverItemsLength: 0,
            needToResetShownRows: false,
        }
    },

    computed: {
        ...mapState({
            materials: state => state.materials.materials,
            permissionsUser: state => state.auth.youPermissions,
            isSuperAdmin: state => state.auth.isSuperAdmin,
            employeeId: state => state.auth.userData.employeeId
        }),

        ...mapGetters({
            getMaterialById: 'materials/getMaterialById',
        }),

        headers() {
            const headers = [
                {
                    text: this.$t('headers.account'),
                    name: 'accountId',
                    value: 'accountId',
                    setValueExpr: val => this.allAccountsBrief.find(c => c.accountId === val)?.name,
                },
                {
                    text: this.$t('headers.input-quantity'),
                    name: 'in',
                    value: 'in',
                    setInputExpr: (inputs, activeInput) => {
						if (activeInput === 'out' && inputs.out) {
							return null
						}
						return inputs.in
					},  
                    inputRules:  !this.tableInputs.in && !this.tableInputs.out ? rules.required : [],
                    type: 'number'     
                },
                {
                    text: this.$t('headers.output-quantity'),
                    name: 'out',
                    value: 'out',
                    setInputExpr: (inputs, activeInput) => {
						if (activeInput === 'in' && inputs.in) {
							return null
						}
						return inputs.out
					},  
                    inputRules: !this.tableInputs.out && !this.tableInputs.in ? rules.required : [],
                    type: 'number'     
                },
                {
                    text: this.$t('headers.item'),
                    name: 'materialId',
                    value: 'materialId',
                    setValueExpr: val => this.getMaterialById(val)?.name,
                    inputRules: rules.required,
                },
                {
                    text: this.$t('headers.notes'),
                    name: 'notes',
                    value: 'notes'
                },
                {
                    text: this.$t('headers.date'),
                    name: 'date',
                    type: 'date',
                    value: 'date',
                    dateFormat: 'DD-MM-YYYY',
                    width: 180
                },
                {
                    text: '',
                    name: 'actions',
                    value: 'actions',
                    width: 60,
                    noInput: true,
                    noNotAvailableText: true,
                    sortable: false
                }
            ]
            return headers
        },

        currentUserData() {
            return this.tab === 0 && this.briefAccounts[0].length
                ? this.briefAccounts[0].find(c => c.id === this.employeeId) || {}
                : {};
        },

        allAccountsBrief() {
            return this.briefAccounts[this.tab];
        },

        types() {
            return [
                { id: 0, name: this.$t('tabs.staff') },
                { id: 1, name: this.$t('tabs.customers') },
                { id: 2, name: this.$t('tabs.external') }
            ]
        },
    },

    methods: {
        updateTableDueTab() {
            this.$refs.table.resetShownRows();
            this.$refs.table.resetInputs();
            this.$router.replace({ name: 'journal-items', query: { tab: this.tab } });

            this.tableInputs.accountId = this.currentUserData.accountId;

            this.updateJournal();
        },

        async updateJournal(isFirstLoad = false) {
            this.loading = true;
            return Promise.all([
                await this.getAllAccount(),
                this.getJournal(null, true),
            ]).finally(() => {
                if (!isFirstLoad) this.loading = false;
            });
        },

        addItem() {
            if (this.loading) return;
            if (this.$refs.table.validate()) {
                this.submitLoading = true;
                this.$store.dispatch('materialTransactions/singleTransaction', { 
                    materialId: this.tableInputs.materialId, 
                    accountId: this.tableInputs.accountId, 
                    out: this.tableInputs.out ? this.tableInputs.out : null, 
                    inAmount: this.tableInputs.in ? this.tableInputs.in : null, 
                    notes: this.tableInputs.notes
                }).then(() => {
                    this.needToResetShownRows = true;
                    this.getJournal(null, false);
                }).finally(() => {
                    this.submitLoading = false;
                    this.tableInputs.in = null;
                    this.tableInputs.out = null;
                    this.$refs.table.resetInputsFocus();
                })
            }
        },

        getAllAccount(isFirstLoad) {
            if (this.tab === 0 && this.briefAccounts[0].length) {
                this.tableInputs.accountId =
                    this.briefAccounts[0].find(c => c.id === this.employeeId)?.accountId;
                return;
            }
            if (this.allAccountsBrief.length) return;

            this.loading = true;
            return this.$store.dispatch('accounts/fetchAllBrief', {
                type: this.tab
            }).then((data) => {
                this.briefAccounts[this.tab].push(...data);
                if (this.tab === 0) {
                    this.tableInputs.accountId = this.currentUserData.accountId
                }
            }).finally(() => {
                if (!isFirstLoad) this.loading = false;
            })
        },

        getJournal(newOptionsScroll, isFirstLoad = false) {
            let page = 1;
            const { itemsPerPage } = this.scrollOptions;
            if (newOptionsScroll) page = newOptionsScroll.page || 1;

            if (this.needToResetShownRows) {
                this.$refs.table.resetShownRows();
                this.needToResetShownRows = false;
            }

            this.loading = true;
			return this.$store.dispatch('materialTransactions/fetchStatement', {
                AccountId: this.currentUserData.accountId,
                Date: this.date,
                Page: page,
                PerPage: itemsPerPage || 50,
                type: this.$route.query.tab,
                WithVisitTransaction: this.tab !== 0,
                SelectOutAmount: this.tab === 0 ? true : null
            }).then(({ data, total }) => {
				const items = data.map(c => ({ 
                    ...c, 
                    in: c.in && c.in.amount, 
                    out: c.out && c.out.amount,
                    materialId: (c.in || c.out).materialId,
                    accountId: c.accountId,
                    notes: c.description,
                }));

                this.serverItemsLength = total;
                this.$refs.table.addScrollItems(items);
			}).finally(() => {
                if (!isFirstLoad) this.loading = false;
            })
		},

        moment,
    },

    mounted() {
        this.tab = +this.$route.query.tab || 0;
        this.$refs.table.resetInputs({
            accountId: this.employeeId,
            date: moment().format('YYYY-MM-DD')
        })
        
        this.loading = true;
        Promise.all([
            this.updateJournal(),
            !this.materials.length ? this.$store.dispatch('materials/fetchAll') : null
        ]).finally(() => {
            this.tableInputs.accountId = this.currentUserData.accountId;
            this.loading = false;
        })
    },

    metaInfo: {
        title: 'Samples journal',
        titleTemplate: '%s | Octopharma'
    }
}
</script>
