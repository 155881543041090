<template>
	<div>
		<v-form
			@submit.prevent="submit"
			ref="form"
			:disabled="Boolean($route.query.preview) || submitLoading"
		>
			<v-row dense class="ma-0">
				<!-- city -->
				<v-col lg="3" md="4" sm="6" cols="12">
					<v-autocomplete
						v-model="employeeData.cityId"
						:items="cities"
						item-text="name"
						item-value="id"
						label="City"
						dense
						:disabled="loading || Boolean($route.query.preview)"
						prepend-icon="mdi-map-marker-multiple"
						persistent-hint
						outlined
                        multiple
                        clearable
                        @change="employeeData.regionIds = regionsAll.filter(c => employeeData.cityId.includes(c.cityId)).map(c => c.id); searchCityId = null"
                        :search-input.sync="searchCityId"
					>
						<!-- @change="getSector()" -->
                        <template v-slot:selection="{ item, index }">
                            <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ employeeData.cityId.length - 1 }})
                            </span>
                        </template>

                        <template v-slot:prepend-item>
                            <v-list-item ripple @click="toggleCities">
                                <v-list-item-action>
                                    <v-icon color='indigo darken-4'>{{ iconCities }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>Select All</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider />
                        </template>
                    </v-autocomplete>
				</v-col>
            
				<!-- sector -->
				<v-col v-if="0" lg="3" md="4" sm="6" cols="12">
					<v-autocomplete
						label="Area"
						v-model="employeeData.sectorId"
						:items="employeeData.cityId? sectorsFiltered : sectors"
						item-value="id"
						item-text="name"
						outlined
						dense
						hide-details="auto"
						:disabled="Boolean($route.query.preview)"
						:loading="loading"
					></v-autocomplete>
						<!-- :multiple="$route.query.employeeType !== 'supervisor'" -->
				</v-col>

                <!-- regions -->
                <v-col lg="3" md="4" sm="6" cols="12">
					<v-autocomplete
                        v-model="employeeData.regionIds"
						:items="employeeData.cityId.length !== 0 ? regionsAll.filter(c => employeeData.cityId.includes(c.cityId)) : []"
						item-text="name"
						item-value="id"
						dense
						hide-details
						outlined
						label="Regions"
						prepend-icon="mdi-medical-bag"
						multiple
						:disabled="Boolean($route.query.preview)"
                        clearable
                        :search-input.sync="searchRegionId"
                        @change="searchRegionId = null"
					> 
                        <template v-slot:selection="{ item, index }">
                            <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ employeeData.regionIds.length - 1 }})
                            </span>
                        </template>

                        <template v-slot:prepend-item>
                            <v-list-item ripple @click="toggleRegion">
                                <v-list-item-action>
                                    <v-icon color='indigo darken-4'>{{ iconRegions }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>Select All</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider />
                        </template>

                    </v-autocomplete>
				</v-col>

                <!-- teams -->
				<v-col lg="3" md="4" sm="6" cols="12">
					<v-autocomplete
						v-model="employeeData.teamId"
						:items="teams"
						item-text="name"
						item-value="id"
						label="teams"
						dense
						hide-details
						outlined
                        multiple
                        clearable
						:disabled="Boolean($route.query.preview)"
						:loading="loading"
                        :search-input.sync="searchTeamId"
                        @change="searchTeamId = null"
					>
                        <template v-slot:selection="{ item, index }">
                            <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ employeeData.teamId.length - 1 }})
                            </span>
                        </template>
                    </v-autocomplete>
				</v-col>

				<!-- lineManager -->
				<v-col lg="3" md="4" sm="6" cols="12">
					<v-autocomplete
						v-model="employeeData.lineManagerId"
						:items="medicalReps.filter(c => c.id !== $route.params.id)"
						item-text="name"
						item-value="id"
						label="Line manager"
						dense
						hide-details
						outlined
						prepend-icon="mdi-account-tie"
						:disabled="Boolean($route.query.preview)"
						:loading="loading"
					/>
				</v-col>

				<v-col lg="3" md="4" sm="6" cols="12">
					<v-autocomplete
                        v-model="employeeData.specialtyIds"
						:items="specialties"
						item-text="name"
						item-value="id"
						dense
						hide-details
						outlined
						label="Specialties"
						prepend-icon="mdi-medical-bag"
						multiple
						:disabled="Boolean($route.query.preview)"
                        :search-input.sync="searchSpecialties"
                        @change="searchSpecialties = null"
					> 

                        <template v-slot:selection="{ item, index }">
                            <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ employeeData.specialtyIds.length - 1 }})
                            </span>
                        </template>

                        <template v-slot:prepend-item>
                            <v-list-item ripple @click="toggle">
                                <v-list-item-action>
                                    <v-icon color='indigo darken-4'>{{ icon }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>Select All</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider />
                        </template>

                    </v-autocomplete>
				</v-col>
                
                <v-col lg="3" md="4" sm="6" cols="12">
					<v-autocomplete
                        v-model="employeeData.roleId"
						:items="roles"
						item-text="name"
						item-value="id"
						dense
						hide-details
						outlined
						label="Role"
						prepend-icon="mdi-medical-bag"
						:disabled="Boolean($route.query.preview)"
					/>
				</v-col>
            
				<!-- target -->
				<v-col v-if="getMedicalRepById($route.params.id).employeeType === 1" lg="3" md="4" sm="6" cols="12">
					<v-text-field
						v-model.number="employeeData.target"
						label="Target"
						type="number"
						:rules="rules.number"
						required
						outlined
						dense
						hide-details="auto"
						:loading="loading"
						prepend-icon="mdi-target-account"
						:disabled="Boolean($route.query.preview)"
					></v-text-field>
				</v-col>

                <!-- leaveDaysNumber -->
				<v-col
					v-if="isSuperAdmin || permissionsUser.ManageLeaveBalances !== roleName.nothing"
					lg="3"
					md="4"
					sm="6"
					cols="12"
				>
					<v-text-field
						v-model.number="employeeData.leaveDaysNumber"
						label="Leave Days Number"
						type="number"
						:rules="rules.number"
						required
						outlined
						dense
						hide-details="auto"
						:loading="loading"
						prepend-icon="mdi-calendar"
						:disabled="Boolean($route.query.preview)"
                        :readonly="permissionsUser.ManageLeaveBalances === roleName.read"
					></v-text-field>
				</v-col>

				<!-- submit button -->
				<v-col v-if="isSuperAdmin || permissionsUser.MedicalReps === roleName.action" cols="12" class="text-end">
					<v-btn
                        v-if="isSuperAdmin || userData !== $route.params.id"
						height="30"
						:color="Boolean($route.query.preview) ? 'edit-color white--text' : 'success white--text' "
						type="submit"
						class="px-md-4"
						:class="{ 'mb-1': $vuetify.breakpoint.smAndDown }"
						:loading="submitLoading"
					>
						<v-icon size="22" class="me-1"> 
							{{ Boolean($route.query.preview) ? 'mdi-pencil' : 'mdi-content-save' }} 
						</v-icon>
						{{ Boolean($route.query.preview) ? 'Edit' : 'save'}}
					</v-btn>
				</v-col>
			</v-row>
		</v-form>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { gender, userType, roleName } from '@/helpers/enums'
import rules from '@/validation rules'
import moment from 'moment'

export default {
    data() {
        return {
            searchCityId: null,
            searchRegionId: null,
            searchTeamId: null,
            searchSpecialties: null,
            roleName,
            // helpers
            submitLoading: false,
            loading: false,
            rules,

            // data
            employeeData: {
                name: null,
                birthDate: null, 
                landlineNumber: null,
                phoneNumber: null,
                cityId: [],
                lineManagerId: null,
                sectorId: null,
                address: null,
                email: null,
                facebookProfileUrl: null, 
                startingDate: null,
                username: null,
                password: null,
                userType: userType.medicalRep,
                gender: gender.male,
                target: null,
                specialtyIds: [],
                roleId: null,
                regionIds: [],
                leaveDaysNumber: 0,
                teams: []
            },
        }
    },

    computed: {
        ...mapState({
            cities: state => state.cities.cities,
            sectors: state => state.sectors.sectors,
            supervisors: state => state.supervisors.supervisors,
            sectorsFiltered: state => state.sectors.sectorsFiltered,
            specialties: state => state.specialties.specialties,
            roles: state => state.rolePermission.roles,
            permissionsUser: state => state.auth.youPermissions,
            isSuperAdmin: state => state.auth.isSuperAdmin,
            regions: state => state.regions.regions,
            regionsAll: state => state.regions.regionsAll,
            medicalReps: state => state.medicalreps.medicalreps,
            teams: state => state.teams.teams,
            userData: state => state.auth.userData.employeeId
        }),

        ...mapGetters({
            getSupervisorById: 'supervisors/getSupervisorById',
            getMedicalRepById: 'medicalreps/getMedicalRepById',
            isAdmin: 'auth/isAdmin',
            getRegionById: 'regions/getRegionById',
            getRegionAllById: 'regions/getRegionAllById',
            getCityById:'cities/getCityById',
        }),

        employeeType() {
            return Number(this.$route.query.employeeType);
        },

        icon() {
            if (this.selectedAllSpecialties) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },

        iconRegions() {
            if (this.selectedAllRegions) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },

        selectedAllSpecialties () {
            return this.employeeData.specialtyIds?.length === this.specialties.length;
        },

        selectedAllRegions () {
            return this.employeeData.regionIds?.length === this.regionsAll.filter(c => this.employeeData.cityId?.includes(c.cityId)).length;
        },

        selectedAllCities () {
            return this.employeeData.cityId?.length === this.cities?.length;
        },

        iconCities() {
            if (this.selectedAllCities) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },
    },

    methods: {
        submit() {
            if (this.$route.query.preview) {
                this.$router.replace({ 
                    name: this.$route.params.name, 
                    params: {
                        id: this.$route.params.id
                    },
                    query: {
                        employeeType: this.$route.query.employeeType
                    } 
                })
            } else {
                if (this.$refs.form.validate()) {
                    this.submitLoading = true;
                    Promise.all([
                        this.$store.dispatch('medicalreps/update', {
                            id: this.$route.params.id,
                            data: this.employeeData
                        }),
                        this.employeeData.target !== null 
                        ? this.$store.dispatch('medicalrepTarget/update', {
                            id: this.$route.params.id,
                            value: this.employeeData.target
                        }) : null
                    ]).then(() => {
                        const moduleName = this.$route.query.employeeType === 'medicalRep' ? 'medicalreps' : 'supervisors'
                        this.$store.dispatch('medicalreps/fetchAll', true)
                        
                        const employeeType =
                            this.$route.query.employeeType === 'medicalRep' ? 'Medical rep' : 'supervisor';
                        this.$eventBus.$emit('show-snackbar', 'Employee is edited', 'info');

                        this.$store.dispatch('leaveManagement/fetchCount', { 
                            employeeId: this.$route.params.id 
                        }).then((data) => {
                            this.employeeData.leaveDaysNumber = data.daysCount
                        })
                    }).finally(() => {
                        this.submitLoading = false;
                        this.$router.replace({
                            name: this.$route.params.name,
                            params: {
                                id: this.$route.params.id
                            },
                            query: {
                                employeeType: this.$route.query.employeeType,
                                preview: true
                            }
                        })
                    })
                }
            }
        },

        getSector () {
            this.loading = true
            this.$store.dispatch('sectors/fetchByCityId', { cityId: this.employeeData.cityId }).finally(() => {
                this.loading = false
            })
            this.$store.dispatch('regions/fetchAll', { cityId: this.employeeData.cityId })
        },

        toggle () {
            this.$nextTick(() => {
                if (this.selectedAllSpecialties) {
                    this.employeeData.specialtyIds = []
                } 
                else {
                    this.employeeData.specialtyIds = this.specialties.map(c => c.id);
                    this.employeeData.specialtyIds.forEach(specialty => {
                    if (this.employeeData.specialtyIds.includes(specialty.id)) {
                        this.employeeData.specialtyIds.push(specialty.id);
                    }
                });  
                }
            })
        },

        toggleRegion() {
            this.$nextTick(() => {
                if (this.selectedAllRegions) {
                    this.employeeData.regionIds = []
                } 
                else {
                    this.employeeData.regionIds = []
                    this.employeeData.regionIds = this.regionsAll.filter(c => this.employeeData.cityId.includes(c.cityId)).map(c => c.id);
                }
            })
        },

        changed () {
            this.employeeData.specialtyIds = [];
            this.specialties.forEach(specialty => {
                if (this.employeeData.specialtyIds.includes(specialty.id)) {
                    this.employeeData.specialtyIds.push(specialty.id);
                }
            })
        },

        toggleCities () { 
            this.employeeData.regionIds = []
            this.$nextTick(() => {
                if (this.selectedAllCities) {
                    this.employeeData.cityId = []
                    this.employeeData.regionIds = []
                } 
                else {
                    this.employeeData.cityId = this.cities.map(c => c.id);
                }
            })
        },
    },

    created() {
        this.loading = true;
        Promise.all([
            !this.regionsAll.length ? this.$store.dispatch('cities/fetchCityMine').then((data) => {
                this.$store.dispatch('regions/fetchAllRegions', { cities: data })
            }) : null,
            !this.sectors.length ? this.$store.dispatch('sectors/fetchAll') : null,
            !this.specialties.length ? this.$store.dispatch('specialties/fetchAll') : null,
            !this.teams.length ? this.$store.dispatch('teams/fetchAll') : null,
            this.permissionsUser.Roles !== roleName.nothing ? this.$store.dispatch('rolePermission/fetchChildrenRole', { id: this.userData }) : null,
            this.$store.dispatch('leaveManagement/fetchCount', { employeeId: this.$route.params.id }).then((data) => {
                this.employeeData.leaveDaysNumber = data.daysCount
            }),
            this.$store.dispatch('medicalreps/fetchAll').then(() => {
                const data = { ...this.getMedicalRepById(this.$route.params.id) };  

                this.employeeData.name = data?.name ? data.name : null;
                this.employeeData.birthDate =
                    data.birthDate ? moment(data.birthDate).format('YYYY-MM-DD') : null;
                this.employeeData.email = data.email ? data.email : null;
                this.employeeData.phoneNumber = data.phoneNumber ? data.phoneNumber : null;
                this.employeeData.landlineNumber = data.landlineNumber ? data.landlineNumber : null;
                this.employeeData.sectorId = data.sectorId ? data.sectorId : null;
                this.employeeData.lineManagerId = data.lineManager ? data.lineManager : null;
                this.employeeData.address = data.address ? data.address : null;
                this.employeeData.facebookProfileUrl = data.facebookProfileUrl ? data.facebookProfileUrl : null;
                this.employeeData.startingDate =
                    data.startingDate ? moment(data.startingDate).format('YYYY-MM-DD') : null;
                this.employeeData.specialtyIds = data.specialtyIds;
                this.employeeData.roleId = data.roleId ? data.roleId : null;
                this.employeeData.username = data.username ? data.username : null;
                this.employeeData.userType = userType.medicalRep;
                this.employeeData.teamId = data.teamId
                this.$store.dispatch('medicalrepTarget/fetch', { id: this.$route.params.id }).then((data) => {
                    this.employeeData.target = data;
                })
                this.employeeData.gender = data.gender;
                this.employeeData.regionIds = data.regionIds

                if (this.employeeData.regionIds?.length) {
                    for (let i = 0; i < data.regionIds.length; i++) {
                        this.employeeData.cityId = this.employeeData.regionIds.map(c => this.getCityById(this.getRegionAllById(c).cityId).id).filter((value, index) => this.employeeData.regionIds.map(c => this.getCityById(this.getRegionAllById(c).cityId).id).indexOf(value) === index) 
                    }    
                }
            }),
        ]).finally(() => {
            this.loading = false;
        })
    }    
}
</script>