import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import cities from './modules/cities'
import regions from './modules/regions'
import sectors from './modules/sectors'
import medicalreps from './modules/medical-reps'
import supervisors from './modules/supervisors'
import materials from './modules/materials'
import materialForms from './modules/materials-forms'
import materialTransactions from './modules/materials-transactions'
import visits from './modules/visits'
import specialties from './modules/specialties'
import entities from './modules/entities'
import modificationRequests from './modules/modification-requests'
import supervisorReports from './modules/supervisor-reports'
import medicalRepRates from './modules/medical-rep-rates'
import warehouses from './modules/warehouses'
import sales from './modules/sales'
import statistics from './modules/statistics'
import medicalrepsRequests from './modules/medicalreps-requests'
import medicalrepTarget from './modules/medicalrep-target'
import exams from './modules/exams'
import salaries from './modules/salaries'
import workload from './modules/workload'
import visitsPlan from './modules/visitsPlan'
import rolePermission from './modules/rolePermission'
import mineVisits from './modules/mine-visits'
import leaveManagement from './modules/leaveManagement'
import accounts from './modules/accounts'
import teams from './modules/teams'
import doubleVisit from './modules/doubleVisit'
import plannedWork from './modules/plannedWork'
import exporter from './modules/exporter'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		auth,
		medicalreps,
		supervisors,
		cities,
		regions,
		sectors,
		materials,
		materialForms,
		materialTransactions,
		visits,
		specialties,
		entities,
		modificationRequests,
		supervisorReports,
		medicalRepRates,
		warehouses,
		sales,
		statistics,
		medicalrepsRequests,
		medicalrepTarget,
		exams,
		salaries,
		workload,
		visitsPlan,
		rolePermission,
		mineVisits,
		leaveManagement,
		accounts,
		teams,
		doubleVisit,
		plannedWork,
		exporter
	}
});
