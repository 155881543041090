<template>
    <div>
        <!-- tabs -->
        <v-tabs v-model="tab" show-arrows height="35">
            <v-tab
                class="text-capitalize "
                :to="{ 
                    name: 'administrative-weekly-report',
                    query: {
                        employeeId: $route.query.employeeId
                    }
                }"
            >
                Reports
            </v-tab>

            <v-tab
                class="text-capitalize "
                :to="{ name: 'administrative-double-visits'}"
            >
                Double Visits
            </v-tab>

            <v-tab
                class="text-capitalize "
                :to="{ name: 'administrative-visits'}"
            >
                Visits
            </v-tab>
            <v-tabs-slider color="primary"></v-tabs-slider>
        </v-tabs>
        <v-divider v-if="!$route.query.preview" class="mb-2"/>

         <!-- child page -->
        <loader v-if="loading"/>
        <router-view v-else/>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tab: 0,
            loading: false
        }
    }
}
</script>
