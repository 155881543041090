var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-wrap justify-sm-space-between justify-end align-center"},[_c('div',{staticClass:"flex-sm-grow-0 flex-grow-1 order-sm-0 order-1"},[_c('v-tabs',{attrs:{"height":"40","grow":""},on:{"change":function($event){return _vm.updateTableDueTab()}},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.types),function(item,index){return _c('v-tab',{key:index,staticClass:"text-capitalize",attrs:{"disabled":_vm.loading}},[_vm._v(" "+_vm._s(item.name)+" ")])}),_c('v-tabs-slider',{attrs:{"color":"primary"}})],2),_c('v-divider',{staticClass:"mx-1"})],1),(_vm.isSuperAdmin || _vm.permissionsUser.MaterialTransactions !== _vm.roleName.nothing)?_c('v-btn',{attrs:{"small":"","to":{ name: 'supervisor-remaining-samples' },"color":"light-blue white--text"}},[_vm._v(" "+_vm._s(_vm.$t('JournalItems.remaining-samples'))+" ")]):_vm._e()],1),_c('v-row',{staticClass:"my-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('bee-date-input',{attrs:{"label":_vm.$t('inputs.date'),"input-format-order":"DD-MM-YYYY","dense":"","disabled":_vm.loading,"append-icon":"mdi-menu-right","prepend-icon":"mdi-menu-left","hide-details":""},on:{"click:append":function($event){_vm.needToResetShownRows = true;
                        _vm.date = _vm.moment(_vm.date).add(1, 'day').format('YYYY-MM-DD');
                        _vm.getJournal();},"click:prepend":function($event){_vm.needToResetShownRows = true;
                        _vm.date = _vm.moment(_vm.date).subtract(1, 'day').format('YYYY-MM-DD');
                        _vm.getJournal();}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('bee-handy-smart-table',{ref:"table",attrs:{"items":[],"headers":_vm.headers,"items-per-page":50,"loading":_vm.loading || _vm.submitLoading,"show-add-row":_vm.permissionsUser.GenerateMaterials !== _vm.roleName.read,"dense":"","zebra":"","hot-inputs":"","hide-inputs-details":"","row-highlight-expression":function (item) { return item.type === 0; },"row-highlight-expression-class":"orange lighten-4","fixed-header":"","height":_vm.$vuetify.breakpoint.height - (_vm.$vuetify.breakpoint.smAndUp ? 190 : 220),"options-scroll":_vm.scrollOptions,"pagination-on-scroll":"","pagination-on-scroll-server-items":"","server-items-length":_vm.serverItemsLength,"disable-input-fields":_vm.submitLoading || _vm.loading},on:{"update:optionsScroll":function($event){_vm.scrollOptions=$event},"update:options-scroll":function($event){_vm.scrollOptions=$event},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addItem.apply(null, arguments)},"sheet-add-clicked":_vm.addItem,"pagination-on-scroll:reach-last-row":_vm.getJournal},scopedSlots:_vm._u([{key:"input.accountId",fn:function(ref){
                        var on = ref.on;
                        var attr = ref.attr;
return [_c('v-autocomplete',_vm._g(_vm._b({attrs:{"items":_vm.tab === 0 ? [_vm.currentUserData] : _vm.allAccountsBrief,"item-value":"accountId","item-text":"name"}},'v-autocomplete',attr,false),on))]}},{key:"input.materialId",fn:function(ref){
                        var on = ref.on;
                        var attr = ref.attr;
return [_c('v-autocomplete',_vm._g(_vm._b({attrs:{"items":_vm.materials,"item-value":"id","item-text":"name"}},'v-autocomplete',attr,false),on))]}},{key:"input.date",fn:function(ref){
                        var on = ref.on;
                        var attr = ref.attr;
return [_c('bee-date-input',_vm._g(_vm._b({attrs:{"dense":"","label":" "}},'bee-date-input',attr,false),on))]}},{key:"no-input.actions",fn:function(){return [_c('tooltip',{attrs:{"text":"Add transaction","right":""}},[_c('v-btn',{attrs:{"color":"success","small":"","icon":"","disabled":_vm.loading},on:{"click":_vm.addItem}},[_c('v-icon',[_vm._v("mdi-check-circle-outline")])],1)],1)]},proxy:true}]),model:{value:(_vm.tableInputs),callback:function ($$v) {_vm.tableInputs=$$v},expression:"tableInputs"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }