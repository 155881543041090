import axios from 'axios';
import * as types from '../mutation-types';

export default {
	namespaced: true,
	state: {
		medicalreps:[],
		usersFlowers: [],
		usersTree: [],
		employees: 0
	},

	actions: {
		fetchAll({ commit }, nonDeleted){
            return window.axios.get('/employees/medicalreps', {
                params: {
                    nonDeleted
                }
            }).then((response) => {
                commit(types.STORE_MEDICALREPS, response.data);
                return response.data;
            })
        },

		fetchByFilter({ commit }, { name, regionIds, teamIds }){
			// console.log(teamIds)
			var urlSearchParams = new URLSearchParams();
			if (name !== null) urlSearchParams.append("name", name);
			regionIds.forEach(id => {
                urlSearchParams.append("regionIds", id);
			});
			teamIds.forEach(id => {
                urlSearchParams.append("teamIds", id);
			});
			urlSearchParams.append('nonDeleted', true);
            return window.axios.get('/employees/medicalreps', {
				params:urlSearchParams
			})
			.then(response => {
                return response.data;
            })
		},
		
		create({ commit }, { data }){
			return window.axios.post('/employees/medicalReps', data);
		},

		update({ commit }, { id, data }){
			return window.axios.put(`/employees/medicalReps/${id}`, data);
		},

		delete({ commit }, { id }){
            return window.axios.delete(`/employees/${id}`);
        },

		toggle({ commit }, { id }){
            return window.axios.put(`/employees/${id}/toggle`);
        },

		fetchUserForUserLogin({ commit }) {
			return window.axios.get('/Employees/Direct').then(({ data }) => {
				commit(types.STORE_USERS_FLOWERS, data)
				return data
			})
		},

		fetchUsersTree({ commit }) {
			return window.axios.get('/Employees/tree').then(({ data }) => {
				commit(types.STORE_USER_TREE, data)
				return data
			})
		},

		fetchEmployeeCount({ commit }) {
			return window.axios.get('/Employees/Count').then(({ data }) => {
				return data
			})
		}
	},

	mutations: {
		[types.STORE_MEDICALREPS](state, medicalrepsData){
            state.medicalreps = medicalrepsData.sort(function(a, b) { if (a.name < b.name) { 
				return -1
			}; 
			if (a.name > b.name) {
				return 1 
			}; 
			return 0 
		});
		},
		[types.STORE_USERS_FLOWERS](state, usersFlowers) {
			state.usersFlowers = usersFlowers
		},
		[types.STORE_USER_TREE] (state, usersTree) {
			state.usersTree = usersTree
		},
	},

	getters:{
		getMedicalRepById: state => id => state.medicalreps.find(c => c.id === id),
		getMedicalRepByName: state => name => state.medicalreps.find(c => c.name === name),
	}
}