<template>
    <div>
        <!-- delete dialog -->
        <delete-dialog ref="DeleteDialog" @on-delete="needToResetItems = true; filter()">
            <p class="mb-0 text-body-1">
                Are you sure you want to <span class="red--text">DELETE</span> the visit ?
            </p>
        </delete-dialog>
        <!-- visit details dialog -->
        <v-dialog
            v-model="dialog"
            transition="dialog-transition"
            max-width="700"
        >
            <!-- dialog content -->
            <v-card>
                <div class="d-flex justify-end background-title-card white--text">
                    <v-card-title
                        class="d-flex w-100 justify-center py-1 d-flex"
                    >
                        Visit details
                    </v-card-title>
                    <v-btn icon @click="dialog = false; view = false; entityTypeCustomer = 1" class="me-2">
                        <v-icon color="white" > mdi-close-circle-outline </v-icon>
                    </v-btn>
                </div>
                <v-card-text class="pb-0 pt-1 px-3">
                    <!-- dialog content -->
                    <!-- samples table -->
                    <div>
                        <p class="text-body-2 font-weight-bold my-1 mb-2">Distributed samples</p>
                        <bee-handy-smart-table
                            ref="sampleTable"
                            v-model="sampleTableInputs"
                            :items="entriesSamples"
                            :headers="headerSamples"
                            zebra
                            dense
                            height="auto"
                            pagination-on-scroll
                            :show-add-row="!view"
                            :editable-rows="!loading"
                            @keypress.enter="isEditSample ? editSamples() : addSamples()"
                        >
                            <!-- product name -->
                            <template v-slot:input.materialId="{ on, attr }">
                                <v-autocomplete
                                    :items="materials"
                                    item-value="id"
                                    item-text="name"
                                    v-on="on"
                                    v-bind="attr"
                                ></v-autocomplete>
                            </template>

                            <!-- action column -->
                            <template v-slot:item.actions="{ item, index }">
                                <div class="d-flex">
                                    <tooltip text="Edit doctor" top>
                                        <v-btn
                                            icon
                                            small
                                            color="edit-color"
                                            :disabled="loading"
                                            @click="() => {
                                                $refs.sampleTable.activeEditForRow(index);
                                                editAddressRowIndex = index;
                                                isEditSample = true;
                                                $nextTick(() => {
                                                    $refs.sampleTable.resetInputsFocus()
                                                })
                                            }"
                                        >
                                            <v-icon>
                                                mdi-pencil
                                            </v-icon>
                                        </v-btn>
                                    </tooltip>
                                    
                                    <tooltip text="Delete doctor" top>
                                        <v-btn
                                            icon
                                            small
                                            @click="deleteSamplesRow({ id: item.id, index })"
                                            color="alert-color"
                                        >
                                            <v-icon>
                                                mdi-delete
                                            </v-icon>
                                        </v-btn>
                                    </tooltip>
                                </div>
                            </template>
                        </bee-handy-smart-table>
                    </div> 

                    <v-row class="mt-1">
                        <!-- other samples table -->
                        <v-col cols="4">
                            <v-text-field
                                v-model="others"
                                class="mb-1"
                                label="Other samples"
                                dense
                                outlined
                                hide-details
                                :readonly="view"
                            >
                            </v-text-field>
                        </v-col>

                        <v-divider vertical class="my-1" />

                        <!-- durationInMinutes-->
                        <v-col cols="4">
                            <v-text-field
                                v-model.number="durationInMinutes"
                                class="mb-1"
                                label="Duration In Minutes"
                                dense
                                outlined
                                hide-details
                                type="number"
                                hide-spin-buttons
                                :readonly="view"
                            >
                            </v-text-field>
                        </v-col>
                        <v-divider vertical class="my-1" />

                        <!-- coreMessage -->
                        <v-col cols="4">
                            <v-text-field
                                v-model="coreMessage"
                                class="mb-1"
                                label="Core message"
                                dense
                                outlined
                                hide-details
                                :readonly="view"
                            >
                            </v-text-field>
                        </v-col>
                        
                        <v-divider vertical class="my-1" />

                        <!-- Notes -->
                        <v-col cols="4">
                            <v-text-field
                                v-model="notes"
                                class="mb-1"
                                label="Notes"
                                dense
                                outlined
                                hide-details
                                :readonly="view"
                            >
                            </v-text-field>
                        </v-col>
                        <v-divider vertical class="my-1" />

                        <!-- Objections-->
                        <v-col cols="4">
                            <v-text-field
                                v-model="objections"
                                class="mb-1"
                                label="Objections"
                                dense
                                outlined
                                hide-details
                                :readonly="view"
                            >
                            </v-text-field>
                        </v-col>

                        <!-- notes -->
                        <v-col cols="4" >
                            <v-text-field
                                v-model="nextCall"
                                class="mb-1"
                                label="Next Call"
                                dense
                                outlined
                                hide-details
                                :readonly="view"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <!-- Unavailable octo Products -->
                    <div v-if="entityTypeCustomer  !== entityType.doctor" class="mt-4">
                        <v-autocomplete
                            v-model="unavailablesMaterials"
                            :items="materials"
                            item-value="id"
                            item-text="name"
                            dense
                            outlined
                            hide-details
                            label="Unavailable octo Products"
                            multiple
                            :readonly="view"
                        />
                    </div>

                    <!-- competitive samples  -->
                    <div v-if="entityTypeCustomer  !== entityType.doctor" class="my-3">
                        <v-textarea
                        	v-model="competitiveSamples"
                        	label="Competitors"
                        	outlined
                        	dense
                        	hide-details
                        	rows="1"
                        	:readonly="view"
                        />
                    </div>

                    <!-- center notes -->
                    <div v-if="entityTypeCustomer === entityType.center">
                        <v-text-field
                            v-model="centerNotes"
                            class="mb-1"
                            label="Center notes"
                            dense
                            outlined
                            hide-details
                            :readonly="view"
                        >
                        </v-text-field>
                    </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn
                        v-if="!view"
                    	text
                    	color="success"
                    	@click="update()"
                    	:loading="submitLoading"
                    >
                        Save
                    </v-btn>
                    <!-- dialog active button -->
                    <v-btn
                    	text
                    	@click="dialog = false; entityTypeCustomer = 1; view = false"
                    	:disabled="submitLoading"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- filter -->
        <v-form @submit.prevent="filter">
            <v-row class="py-3 mx-2">
                <!-- city region filter -->
                <v-col lg="4" md="5" sm="8" cols="12" class="pa-1">
                    <v-row class="justify-space-between ma-0">
                        <!-- city name -->
                        <v-col md="5" sm="4" cols="6" class="pa-0 pe-1">
                            <v-autocomplete
                                v-model="cityId"
                                :items="cities"
                                item-text="name"
                                item-value="id"
                                label="City"
                                dense
                                prepend-icon="mdi-map-marker-multiple"
                                hide-details
                                append-icon
                                :disabled="loading"
                                @change="fetchRegionsDueCity"
                                outlined
                                multiple
                                clearable
                                :search-input.sync="searchCityId"
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item
                                        ripple
                                        @click="toggleCities"
                                    >
                                        <v-list-item-action>
                                            <v-icon>
                                                {{ iconCities }}
                                            </v-icon>
                                        </v-list-item-action>
                                        
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ $t('select-all') }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                    <span
                                        v-if="index === 1"
                                        class="grey--text text-caption"
                                    >
                                        (+{{ cityId.length - 1 }})
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>
        
                        <!-- region -->
                        <v-col md="7" sm="8" cols="6" class="pa-0">
                            <v-autocomplete
                                v-model="regionIds"
                                :items="regionsAll.filter(c => cityId.includes(c.cityId))"
                                item-text="name"
                                item-value="id"
                                label="Regions"
                                dense
                                multiple
                                clearable
                                hide-details
                                append-icon
                                @change="fetchEntitiesDueRegion"
                                :loading="regionsLoading"
                                :disabled="loading || regionsLoading || cityId === []"
                                outlined
                                :search-input.sync="searchRegion"
                            >
                            
                                <template v-slot:selection="{ item, index }">
                                    <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                    <span
                                        v-if="index === 1"
                                        class="grey--text text-caption"
                                    >
                                        (+{{ regionIds.length - 1 }})
                                    </span>
                                </template>

                                <template v-slot:prepend-item>
                                    <v-list-item ripple @click="toggle">
                                        <v-list-item-action>
                                            <v-icon>
                                                {{ icon }}
                                            </v-icon>
                                        </v-list-item-action>
                                        
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Select All
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="auto" :align-self="$vuetify.breakpoint.smAndDown ? 'center' : 'start' " class="pa-0">
                    <v-radio-group
                        v-model="dateType"
                        row
                        class="mt-1"
                        hide-details
                    >
                        <v-radio
                            label="Daily date"
                            value="1"
                            :disabled="loading"
                        ></v-radio>
                        <v-radio
                            label="From date / To date"
                            value="2"
                            :disabled="loading"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col v-if="dateType === '2'" md="2" sm="3" cols="12" :class="{ 'pa-1': $vuetify.breakpoint.smAndDown }" >
                    <bee-date-picker
                        v-model="startDate"
                        input-label="Start Date"
                        menu-picker
                        dense
                        hide-details
                        :input-props="{
                            outlined: true,
                            dense: true
                        }"
                    ></bee-date-picker>
                </v-col>

                <!-- end date -->
                <v-col v-if="dateType === '2'" md="2" sm="3" cols="12"  :class="{ 'pa-1': $vuetify.breakpoint.smAndDown }">
                    <bee-date-picker
                        v-model="endDate"
                        input-label="End Date"
                        menu-picker
                        dense
                        hide-details
                        :input-props="{
                            outlined: true,
                            dense: true
                        }"
                    ></bee-date-picker>
                </v-col>
                <!-- filter -->
                <v-col v-if="dateType === '1'" cols="12" sm="6" md="3" class="pa-1">
                    <bee-date-input
                        v-model="date"
                        append-icon="mdi-menu-right"
                        prepend-icon="mdi-menu-left"
                        :label="$t('inputs.date')"
                        input-format-order="DD-MM-YYYY"
                        dense
                        :loading="loading"
                        hide-details
                        @click:append="
                            needToResetShownRows = true;
                            addDate();
                            filter()
                        "
                        @click:prepend="
                            needToResetShownRows = true;
                            subDate();
                            filter()
                        "
                    ></bee-date-input>
                </v-col>
                
                <!-- medical rep -->
                <v-col md="2" sm="4" cols="12" class="pa-1 d-flex align-start">
                    <v-autocomplete
                        v-model="medicalRepId"
                        :items="medicalReps.filter(c => c.employeeType === userType.medicalRep)"
                        item-text="name"
                        item-value="id"
                        label="Medical Rep"
                        prepend-icon="mdi-account-tie"
                        hide-details
                        clearable
                        :loading="loading"
                        :disabled="loading"
                        outlined
                        dense
                    ></v-autocomplete>
                </v-col>
                <v-col class="d-flex justify-start pa-1" align-self="center">
                    <v-btn
                        color="success"
                        class="px-sm-4 mx-2"
                        :loading="loading"
                        :disabled="loading"
                        type="submit"
                        small
                        @click="needToResetItems = true; filter()"
                    >
                        <v-icon> mdi-magnify </v-icon>
							{{$t('search')}}
						</v-btn>
						<v-btn
							class="px-sm-4"
							:disabled="loading"
							color="alert-color white--text"
							small
                            @click="resetFilter(); filter()"
						>
							{{$t('reset')}}
						</v-btn>
                </v-col>
            </v-row>
        </v-form>

        <bee-handy-table
			:headers="headers"
			:items="visitsOrder"
			ref="table"
			hide-default-footer
			dense
			class="my-2 px-2 mx-2"
			:loading="loading"
			hide-inputs-details
			:data-table-props="{
                singleExpand: true,
				expandedSync: expanded,
				showExpand: true,
                itemKey: 'employeeId',
                disableSort: true
			}"
            :pagination-on-scroll-auto-reset-shown="false"
            pagination-on-scroll-server-items
            :items-per-page="20"
            :options-scroll.sync="scrollOptions"
            :server-items-length="serverItemsLength"
            @pagination-on-scroll:reach-last-row="filter"
            zebra
        >
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="tab">
                    <bee-handy-table
						:headers="head"
						:items="item.visits"
						hide-default-footer
						class="tab"
						table-class="baby-blue rounded-0 elevation-0"
                        zebra
                        :data-table-props="{
                            disableSort: true
			            }"
                        pagination-on-scroll
					>
                        <!-- action column -->
                        <template v-slot:item.actions="{ item, index }">
                            <div class="d-flex">
                                <v-btn
                                    icon
                                    small
                                    color="primary"
                                    :loading="loadingView[index] && view"
                                    :disabled="loading || Boolean($route.query.preview)"
                                    @click="view = true; visitId = item.id; loadingView[index] = true; entityTypeCustomer = item.entityType; fetchVisitData(null, item.id, index)"
                                >
                                    <v-icon >mdi-eye</v-icon>
                                </v-btn>
                                <v-btn
                                    icon
                                    small
                                    color="success"
                                    :loading="loadingView[index] && !view"
                                    @click="visitId = item.id; loadingView[index] = true; entityTypeCustomer = item.entityType; fetchVisitData(null, item.id, index)"
                                >
                                    <v-icon>
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>

                                <v-btn
                                    icon
                                    small
                                    color="red lighten-1"
                                    @click="$refs.DeleteDialog.activeDeleteDialog(item.id, 'visits')"
                                >
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </div>
                        </template>
                    </bee-handy-table>
                </td>
            </template>

            <!-- actions -->
			<template v-slot:item.action="{ item }">
                <tooltip text="Edit visit" top>
                    <v-btn
                        icon
                        small
                        color="edit-color"
                        :loading="visitId === item.id"
                        :disabled="loading || Boolean($route.query.preview)"
                        @click="visitId = item.id; fetchVisitData(tabs.map(c => c.id)[tab], item.id);"
                    >
                        <v-icon >mdi-pencil</v-icon>
                    </v-btn>
                </tooltip>

                <tooltip text="Delete" top>
                    <v-btn
                        icon
                        small
                        color="red lighten-1"
                        @click="$refs.DeleteDialog.activeDeleteDialog(item.id, 'visits')"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </tooltip>
			</template>
        </bee-handy-table>
    </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import Tooltip from '@/components/Tooltip'
import { userType, entityType } from '@/helpers/enums'
import DeleteDialog from '@/components/DeleteDialog'
export default {
    components: {
        Tooltip,
        DeleteDialog
    },

    data() {
        return {
            submitLoading: false,
            view: false,
            entityType,
            entityTypeCustomer: 1,
            entriesSamples: [],
            materialsEmployee: [],
            isEditSample: null,
            sampleTableInputs: {},
            editAddressRowIndex: null,
            createdAtForVisit: null,
            entityIForRow: null,
            // dialog: false,
            searchCityId: null,
            searchRegion: null,
            dateType: '1',
            loadingView: [
                false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
            ],
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            endDate: moment().endOf('month').format('YYYY-MM-DD'),
            dialog: false,
            userType,
            cityId: [],
            regionIds: [],
            regionsLoading: false,
            medicalRepId: [],
            date: moment().format('YYYY-MM-DD'),
            needToResetShownRows: false,
            selections:{
                label: ['Customer', 'Doctor', 'Pharmacy', 'Center'],
                entities: [],
            },
            loading: false,
            expanded: [],
			singleExpand: false,

            // details visit
            samples: [],
            others: null,
            notes: null,
            objections: null,
            coreMessage: null,
            durationInMinutes: 0,
            nextCall: null,
            unavailablesMaterials: [],
            competitiveSamples: [],
            centerNotes: null,
            scrollOptions: {},
            serverItemsLength: 0,
            needToResetItems: false
        }
    },

    computed: {
        ...mapState({
            cities: state => state.cities.cities,
            regionsAll: state => state.regions.regionsAll,
            medicalReps: state => state.medicalreps.medicalreps,
            specialties: state => state.specialties.specialties,
            visitsOrder: state => state.visits.visitsOrder,
            materials: state => state.materials.materials,
        }),
        ...mapGetters({
            getMedicalRepById: 'medicalreps/getMedicalRepById',
            getMaterialById: 'materials/getMaterialById',
            getVisitOrderByMedicalRep: 'visits/getVisitOrderByMedicalRep',
            getCityById: 'cities/getCityById',
            getRegionAllById: 'regions/getRegionAllById'
        }),
        headers() {
            return [
                {
                    text: this.$t('headers.employee'),
                    name: 'employeeName',
                    value: 'employeeName'
                },
                {
                    text: this.$t('headers.team'),
                    name: 'team',
                    value: 'teamName'
                },
                {
                    text: this.$t('headers.city'),
                    name: 'city',
                    value: 'employeeId',
                    setValueExpr: val => this.getCityById(this.getRegionAllById(this.getMedicalRepById(val)?.regionIds[0])?.cityId)?.name
                },
                {
                    text: this.$t('headers.number-of-visits'),
                    name: 'totalVisitCount',
                    value: 'employeeId',
                    setValueExpr: (val) => this.getVisitOrderByMedicalRep(val)?.doneVisitConut + '/' + this.getVisitOrderByMedicalRep(val)?.totalVisitConut
                }
            ]
        },

        head () {
            return [
                {
                    text: this.$t('headers.date'),
                    name: 'date',
                    value: 'createdAt',
                    setValueExpr: val => moment(val).format('YYYY-MM-DD'),
                    class:'text-capitalize text-body-2 font-weight-bold',
                },
                {
                    text: this.$t('headers.specialty'),
                    name: 'specialty',
                    value: 'entitySpecialtyName',
                    setValueExpr: val => val.join(', '),
                    class:'text-capitalize text-body-2 font-weight-bold',
                },
                {
                    text: this.$t('headers.class'),
                    name: 'class',
                    value: 'entityWorkloadValue',
                    class:'text-capitalize text-body-2 font-weight-bold',
                },
                {
                    text: this.$t('headers.customer'),
                    name: 'customer',
                    value: 'entityName',
                    class:'text-capitalize text-body-2 font-weight-bold',
                },
                {
                    text: this.$t('headers.region'),
                    name: 'region',
                    value: 'entityRegionName',
                    class:'text-capitalize text-body-2 font-weight-bold',
                },
                {
                    text: this.$t('headers.notes'),
                    name: 'notes',
                    value: 'notes',
                    class:'text-capitalize text-body-2 font-weight-bold',
                },
                {
                    text: this.$t('headers.core-message'),
                    name: 'core-message',
                    value: 'coreMessage',
                    class:'text-capitalize text-body-2 font-weight-bold',
                },
                {
                    text: this.$t('headers.objection'),
                    name: 'objection',
                    value: 'objections',
                    class:'text-capitalize text-body-2 font-weight-bold',
                },
                {
                    text: this.$t('headers.next-call'),
                    name: 'next-call',
                    value: 'purpose',
                    class:'text-capitalize text-body-2 font-weight-bold',
                },
                {
                    text: this.$t('headers.actions'),
                    name: 'actions',
                    value: 'actions'
                }
            ]
        },

        headerSamples() {
            const headers = [
                {
                    text: 'Samples',
                    name: 'materialId',
                    value: 'materialId',
                    setValueExpr: val => this.getMaterialById(val)?.name
                },
                {
                    text: 'quantity',
                    name: 'amount',
                    value: 'amount',
                    type: 'number'
                }
            ]
            if (!this.view) {
                headers.push({
                    text: 'actions',
                    name: 'actions',
                    value: 'actions',
                    noInput: true
                })
            }
            return headers
        },
        
        selectedAllCities () {
            return this.cityId.length === this.cities.length;
        },

        iconCities () {
            if (this.selectedAllCities) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },

        selectedAllRegions () {
            return this.regionIds.length === this.regionsAll.filter(c => this.cityId?.includes(c.cityId)).length;
        },

        icon() {
            if (this.selectedAllRegions) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },
    },

    methods: {
        deleteSamplesRow(rowData) {
            this.$refs.sampleTable.resetEdit();
			this.isEditSample = false;
			this.entriesSamples.splice(rowData.index, 1);

            for (let i = 0; i < this.entriesSamples.length; i++) {
                if (this.entriesSamples[i].id > rowData.id) {
                    this.entriesSamples[i].id--;
                }
            }
			
			this.$nextTick(() => {
				this.$refs.sampleTableInputs.resetInputs();
				this.$refs.sampleTableInputs.resetInputsFocus()
			})
		},
        editSamples() {
            if (this.$refs.sampleTable.validate()) {
                this.entriesSamples[this.editAddressRowIndex].materialId = this.sampleTableInputs.materialId
                this.entriesSamples[this.editAddressRowIndex].amount = this.sampleTableInputs.amount
                
                this.$refs.sampleTable.resetEdit();
                this.isEditSample = false;
                this.$nextTick(() => {
                    this.$refs.sampleTable.resetInputs();
					this.$refs.sampleTable.resetInputsFocus()
                })
			} 
        },

        addSamples() {
            if (this.$refs.sampleTable.validate()) {
                const itemInTable = this.entriesSamples.find(c => c.materialId === this.sampleTableInputs.materialId);
                if (itemInTable) {
                    itemInTable.amount = itemInTable.amount + 1
                } else {
                    this.entriesSamples.push({
                        ...this.sampleTableInputs,
                    })
                    this.entriesSamples.map((c, index) => ({ ...c, id: index }))
                }
                this.$refs.sampleTable.resetInputs();
                this.$refs.sampleTable.resetInputsFocus();
            }
        },
        
        fetchRegionsDueCity(){
            this.entityId = null
            this.selections.entities = [];
            this.regionIds = [];
            if (!this.cityId) return;
            this.regionsAll.forEach(region => {
                if (this.cityId.includes(region.cityId)) {
                    this.regionIds.push(region.id);
                }
            });

            this.selections.entities = [];
            if (this.regionIds.length === 0) return;

            this.entityLoading = true;
            this.searchCityId = null
        },
        
        // select all inputs
        toggleCities () {
            this.$nextTick(() => {
                if (this.selectedAllCities) {
                    this.cityId = []
                    this.regionIds = []
                } 
                else {
                    this.cityId = this.cities.map(c => c.id);
                    this.fetchRegionsDueCity()
                }
            })
        },

        toggle () {
            this.$nextTick(() => {
                if (this.selectedAllRegions) {
                    this.regionIds = []
                } 
                else {
                    this.regionIds = []
                    this.regionIds = this.regionsAll.filter(c => this.cityId.includes(c.cityId)).map(c => c.id);
                }
            })
        },
        fetchEntitiesDueRegion(){
            this.searchRegion = null
            this.selections.entities = [];
            if (this.regionIds.length === 0) return;

            this.entityLoading = true;
            this.$store.dispatch('entities/fetchByFilter', {
                type: this.entityTypeForCurrentTab,
                regionIds: this.regionIds,
                nonDeleted: true,
                OrderByName: true
            })
            .then((response) => {
                this.selections.entities = response.data;
            })
            .finally(() => {
                this.entityLoading = false
            })
        },

        addDate () {
            this.date = moment(this.date).add(1, 'day').format('YYYY-MM-DD');
            return this.date
        },

        subDate() {
            this.date = moment(this.date).subtract(1, 'day').format('YYYY-MM-DD')
            return this.date
        },

        filter(newOptionsScroll, isFirstLoad = false) {
            this.loading = true;
            let page = 1;

            if (newOptionsScroll) {
                page = newOptionsScroll.page;
            }

            const { itemsPerPage } = this.scrollOptions;
            return this.$store.dispatch('visits/fetchVisitsOrder', {
                FromDate: this.dateType === '1' ? this.date : this.startDate, 
                ToDate: this.dateType === '1' ? this.date : this.endDate,
                EmployeeId: this.medicalRepId,
                RegionIds: this.regionIds.length ? this.regionIds : null, 
                Page: page,
                PerPage: 20,
            })
            .then(({ data, total }) => {
                if (this.needToResetItems) {
                    this.$refs.table.resetShownRows();
                    this.needToResetItems = false;
                }
                this.totalVisits = total;
                this.$refs.table.addScrollItems(data);
                this.serverItemsLength = this.totalVisits;
            })
            .finally(() => {
                this.loading = false;
            })
        },

        fetchVisitData(type, visitId, index){
            this.$store.dispatch('visits/fetchById', { id: visitId })
            .then((data) => {
                this.entriesSamples = data.materialAmounts.map((c, index) => ({ ...c, id: index }));
                this.others = data.otherSamples;
                this.notes = data.notes;
                this.objections = data.objections;
                this.nextCall = data.purpose;
                this.coreMessage = data.coreMessage;
                this.durationInMinutes = data.durationInMinutes;

                this.unavailablesMaterials = data.unavailableMaterialIds;
                this.competitiveSamples = data.competitiveSamples.join(', ');
                this.centerNotes = null;
                
                this.dialog = true;
                this.entityIForRow = data.entityId
                this.createdAtForVisit = data.createdAt

                if (type === entityType.pharmacy || this.tab === 0) {
                    // data.unavailableMaterialIds.forEach((element) => {
                    //     this.unavailablesMaterials.push(this.getMaterialById(element).name)
                    // });
                    // this.competitiveSamples = data.competitiveSamples;
                } else if (type === entityType.center || this.tab === 0) {
                    this.centerNotes = data.nextVisitNotes;
                }
                this.$store.dispatch('materialTransactions/fetchRemaining', { id: data.employeeId }).then((data) => {
                    this.materialsEmployee = data
                })

                // this.visitId = null;
            }).finally(() => {
                this.loadingView[index] = false
            })
        },

        update() {
            this.submitLoading = true
            this.$store.dispatch('visits/updateVisit', {
                id: this.visitId,
                entityId: this.entityIForRow, 
                otherSamples: this.otherSamples, 
                objections: this.objections,
                nextVisitNotes: this.centerNotes,
                purpose: this.nextCall, 
                materialAmounts: this.entriesSamples.map(c => ({ materialId: c.materialId, amount: c.amount })),
                unavailableMaterialIds: this.unavailablesMaterials, 
                competitiveSamples: [this.competitiveSamples], 
                createdAt: this.createdAtForVisit,
                coreMessage: this.coreMessage, 
                durationInMinutes: this.durationInMinutes,
                notes: this.notes
           }).then(() => {
                this.filter()
                this.dialog = false
                this.entityTypeCustomer = 1
                this.view = false
           }).finally(() => {
                this.dialog = false
                this.visitId = null
                this.submitLoading = false
           })
        },
        resetFilter() {
            this.cityId = []
            this.regionIds = []
            this.dateType = 1
            this.startDate = moment().startOf('month').format('YYYY-MM-DD')
            this.endDate = moment().endOf('month').format('YYYY-MM-DD')
            this.date = moment().format('YYYY-MM-DD')
            this.medicalRepId = null
        }
    },

    created() {
        this.loading = true
        Promise.all([
            !this.cities.length ? this.$store.dispatch('cities/fetchCityMine').then((data) => {
                this.$store.dispatch('regions/fetchAllRegions', { cities: data })
            }) : null,
            !this.medicalReps.length ? this.$store.dispatch('medicalreps/fetchAll') : null,
            !this.specialties.length ? this.$store.dispatch('specialties/fetchAll') : null,
            !this.materials.length ? this.$store.dispatch('materials/fetchAll') : null
        ]).then(() => {
            this.filter()
        })
    }
}
</script>
