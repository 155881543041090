<template>
    <div>
        <div v-if="0" class="d-flex justify-space-between">
            <!-- BACK -->
            <v-btn
                color="grey darken-2 white--text"
                small
                :to="{ name: 'staff' }"
                class="mb-sm-4 mb-md-0"
            >
                <v-icon small class="me-1"> mdi-arrow-left-circle </v-icon>
                Back
            </v-btn>
        </div>

        <v-row :no-gutters="$vuetify.breakpoint.mdAndUp" dense class="ma-0">
            <!-- left side -->
            <v-col md="3" cols="12" class="pe-md-3">
                <v-form @submit.prevent="submit" ref="form" class="form-info">
                    <v-row v-if="$vuetify.breakpoint.mdAndUp" class="ma-0" dense>  
                        <!-- employee photo -->
                        <v-col md="12" sm="6" cols="12" align-self="center">
                            <div class="d-flex justify-center pb-0">
                                <div class="personal-image">
                                    <v-img
                                        :height="!$vuetify.breakpoint.sm ? 100 : 140"
                                        :max-height="!$vuetify.breakpoint.sm ? 100 : 140"
                                        :width="!$vuetify.breakpoint.sm ? 100 : 140"
                                        :max-width="!$vuetify.breakpoint.sm ? 100 : 140"
                                        class="rounded-circle"
                                        :src="previewPhoto || medicalRepImagePath || require(
                                            medicalRepData.gender === gender.male
                                                ? '@/assets/male.jpg'
                                                : '@/assets/avatar-10-vector-37332908.jpg'
                                        )"
                                        :gradient="medicalRepPhoto ? '#eee2, #eee7, #eee2' : null"
                                    ></v-img>
                                    
                                    <div class="personal-image__actions">
                                        <v-btn
                                            v-if="medicalRepPhoto !== null"
                                            icon
                                            width="30"
                                            height="30"
                                            min-width="0"
                                            color="red lighten-1"
                                            @click="previewPhoto = null; medicalRepPhoto = null"
                                        >
                                            <v-icon> mdi-delete </v-icon>
                                        </v-btn>

                                        <v-file-input
                                            v-model="medicalRepPhoto"
                                            class="ma-0 pa-0"
                                            accept="image/*"
                                            label="employee photo"
                                            placeholder="Pick a photo"
                                            :prepend-icon="medicalRepPhoto !== null ? 'mdi-image' : 'mdi-plus-circle'"
                                            hide-input
                                            @change="onImageChange"
                                        ></v-file-input>
                                    </div>
                                </div>
                            </div>
                        </v-col>

                        <v-col cols="12" sm="6" md="12">
                            <!-- name -->
                            <v-text-field
                                v-model="medicalRepData.name"
                                label="Employee"
                                hide-details
                                outlined
                                dense
                                prepend-icon="mdi-account-tie"
                                :disabled="!isEditActive"
                                class="mb-1"
                            ></v-text-field>
                            
                            <!-- gender -->
                            <v-radio-group
                                v-model="medicalRepData.gender"
                                hide-details
                                row
                                dense
                                class="ma-0 pt-0"
                            >
                                <span class="d-flex align-center me-1">
                                    <v-icon class="me-2" size="26"> mdi-gender-male-female</v-icon>
                                    Gender:
                                </span>
                                <v-radio
                                    v-for="gender in genders"
                                    :key="gender.id"
                                    :label="gender.name"
                                    :value="gender.id"
                                    dense
                                    hide-details
                                    class="ma-0 me-2"
                                    :disabled="!isEditActive"
                                ></v-radio>
                            </v-radio-group>
                
                            <!-- birthDate & phone -->
                            <template v-if="$vuetify.breakpoint.smAndDown">
                                <!-- birthDate -->
                                <bee-date-picker
                                    v-model="medicalRepData.birthDate"
                                    input-label="Birthday"
                                    menu-picker
                                    dense
                                    hide-details
                                    :input-props="{
                                        outlined: true,
                                        dense: true,
                                        prependIcon: 'mdi-calendar'
                                    }"
                                    :disabled="!isEditActive"
                                ></bee-date-picker>
                                
                                <!-- phone -->
                                <v-text-field 
                                    v-model="medicalRepData.phoneNumber"
                                    label="Phone number"
                                    dense
                                    hide-details
                                    prepend-icon="mdi-phone"
                                    outlined
                                    :disabled="!isEditActive"
                                    class="mt-2"
                                />
                            </template>
                        </v-col>

                        <!-- birthDate & phone -->
                        <template v-if="$vuetify.breakpoint.mdAndUp">
                            <!-- birthday -->
                            <v-col cols="12" sm="6" md="12">
                                <bee-date-picker
                                    v-model="medicalRepData.birthDate"
                                    input-label="Birthday"
                                    elevation="2"
                                    menu-picker
                                    dense
                                    hide-details
                                    :input-props="{
                                        outlined: true,
                                        dense: true,
                                        prependIcon: 'mdi-calendar'
                                    }"
                                    :disabled="!isEditActive"
                                ></bee-date-picker>
                            </v-col>
                            
                            <!-- phone -->
                            <v-col cols="12" sm="6" md="12">
                                <v-text-field 
                                    v-model="medicalRepData.phoneNumber"
                                    label="Phone number"
                                    dense
                                    hide-details
                                    outlined
                                    prepend-icon="mdi-phone"
                                    :disabled="!isEditActive"
                                />
                            </v-col>
                        </template>

                        <!-- Telephone number -->
                        <v-col cols="12" sm="6" md="12">
                            <v-text-field
                                label="Telephone number"
                                v-model="medicalRepData.landlineNumber"
                                outlined
                                dense
                                prepend-icon="mdi-phone-classic"
                                hide-details="auto"
                                :disabled="!isEditActive"
                            ></v-text-field>
                        </v-col>
        
                        <!-- address -->
                        <v-col cols="12" sm="6" md="12">
                            <v-text-field 
                                v-model="medicalRepData.address"
                                label="Full Address"
                                dense
                                hide-details
                                outlined
                                prepend-icon="mdi-map-marker"
                                :disabled="!isEditActive"
                            />
                        </v-col>
                        
                        <!-- email -->
                        <v-col cols="12" sm="6" md="12">
                            <v-text-field 
                                v-model="medicalRepData.email"
                                label="Email"
                                dense
                                hide-details
                                outlined
                                prepend-icon="mdi-email"
                                :disabled="!isEditActive"
                            />
                        </v-col>   
                
                        <!-- facebookProfileUrl -->
                        <v-col cols="12" sm="6" md="12">
                            <v-text-field 
                                v-model="medicalRepData.facebookProfileUrl"
                                label="Facebook profile Url"
                                dense
                                hide-details
                                outlined
                                prepend-icon="mdi-facebook"
                                :disabled="!isEditActive"
                            />
                        </v-col>  
                
                        <!-- startingDate -->
                        <v-col cols="12" sm="6" md="12">
                            <bee-date-picker
                                v-model="medicalRepData.startingDate"
                                input-label="Hiring date"
                                elevation="2"
                                menu-picker
                                dense
                                hide-details
                                :input-props="{
                                    outlined: true,
                                    dense: true,
                                    prependIcon: 'mdi-calendar'
                                }"
                                :disabled="!isEditActive"
                            ></bee-date-picker>
                        </v-col>

                        <!-- username -->
                        <v-col cols="12" sm="6" md="12">
                            <v-text-field
                                v-model="medicalRepData.username"
                                label="Username"
                                elevation="2"
                                dense
                                hide-details
                                outlined
                                prepend-icon="mdi-account"
                                :disabled="!isEditActive"
                            />
                        </v-col>

                        <!-- password -->
                        <v-col cols="12" sm="6" md="12">
                            <v-text-field
                                v-model="medicalRepData.password"
                                label="Password"
                                elevation="2"
                                dense
                                hide-details
                                outlined
                                prepend-icon="mdi-eye"
                                :disabled="!isEditActive"
                            />
                        </v-col>

                        <!-- action -->
                        <v-col cols="12" class="pa-md-0">
                            <v-btn
                                v-if="isSuperAdmin || permissionsUser.MedicalReps"
                                :color="!isEditActive ? 'edit-color white--text' : 'success white--text'"
                                :loading="submitLoading"
                                type="submit"
                                height="35"
                                :width="!isEditActive ? '100%' : '70%'"
                            >
                                <v-icon size="22" class="me-1" >
                                    {{ !isEditActive ? 'mdi-pencil' : 'mdi-content-save-edit'}} 
                                </v-icon>
                                {{ !isEditActive ? 'Edit data' : 'Save'}}
                            </v-btn>
                            <v-btn
                            	v-if="isEditActive"
                                height="35"
                                width="27%"
                                class="ms-2"
                            	@click="isEditActive = false"
                            >
                                Cancel
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-else class="ma-0" dense>  
                        <!-- employee photo -->
                        <v-col md="12" sm="6" cols="12" align-self="center">
                            <div class="d-flex justify-center pb-0">
                                <div class="personal-image">
                                    <v-img
                                        :height="!$vuetify.breakpoint.sm ? 100 : 140"
                                        :max-height="!$vuetify.breakpoint.sm ? 100 : 140"
                                        :width="!$vuetify.breakpoint.sm ? 100 : 140"
                                        :max-width="!$vuetify.breakpoint.sm ? 100 : 140"
                                        class="rounded-circle"
                                        :src="previewPhoto || medicalRepImagePath || require(
                                            medicalRepData.gender === gender.male
                                                ? '@/assets/male.jpg'
                                                : '@/assets/avatar-10-vector-37332908.jpg'
                                        )"
                                        :gradient="medicalRepPhoto ? '#eee2, #eee7, #eee2' : null"
                                    ></v-img>
                                    
                                    <div class="personal-image__actions">
                                        <v-btn
                                            v-if="medicalRepPhoto"
                                            icon
                                            width="30"
                                            height="30"
                                            min-width="0"
                                            color="red lighten-1"
                                            @click="previewPhoto = null; medicalRepPhoto = null"
                                        >
                                            <v-icon> mdi-delete </v-icon>
                                        </v-btn>

                                        <v-file-input
                                            v-model="medicalRepPhoto"
                                            class="ma-0 pa-0"
                                            accept="image/*"
                                            label="employee photo"
                                            placeholder="Pick a photo"
                                            :prepend-icon="medicalRepPhoto ? 'mdi-image' : 'mdi-plus-circle'"
                                            hide-input
                                            @change="onImageChange"
                                        ></v-file-input>
                                    </div>
                                </div>
                            </div>

                            <!-- name -->
                            <v-text-field
                                v-model="medicalRepData.name"
                                label="Employee"
                                hide-details
                                outlined
                                dense
                                prepend-icon="mdi-account-tie"
                                :disabled="!isEditActive"
                                class="mb-1"
                            ></v-text-field>

                            <!-- gender -->
                            <v-radio-group
                                v-model="medicalRepData.gender"
                                hide-details
                                row
                                dense
                                class="ma-0 pt-0"
                            >
                                <span class="d-flex align-center me-1">
                                    <v-icon class="me-2" size="26"> mdi-gender-male-female</v-icon>
                                    Gender:
                                </span>
                                <v-radio
                                    v-for="gender in genders"
                                    :key="gender.id"
                                    :label="gender.name"
                                    :value="gender.id"
                                    dense
                                    hide-details
                                    class="ma-0 me-2"
                                    :disabled="!isEditActive"
                                ></v-radio>
                            </v-radio-group>
                            
							<!-- birthDate & phone -->
							<template>
								<!-- birthDate -->
								<bee-date-picker
									v-model="medicalRepData.birthDate"
									input-label="Birthday"
									menu-picker
									dense
									hide-details
									:input-props="{
										outlined: true,
										dense: true,
										prependIcon: 'mdi-calendar'
									}"
									:disabled="!isEditActive"
								></bee-date-picker>
                                
                                <!-- phone -->
                                <v-text-field 
                                    v-model="medicalRepData.phoneNumber"
                                    label="Phone number"
                                    dense
                                    hide-details
                                    prepend-icon="mdi-phone"
                                    outlined
                                    :disabled="!isEditActive"
                                    class="mt-2"
                                />
                            </template>

                            <!-- Telephone number -->
                            <v-text-field
                                label="Telephone number"
                                v-model="medicalRepData.landlineNumber"
                                outlined
                                dense
                                prepend-icon="mdi-phone-classic"
                                hide-details="auto"
                                :disabled="!isEditActive"
                                class="mt-2"
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <!-- address -->
                            <v-text-field 
                                v-model="medicalRepData.address"
                                label="Full Address"
                                dense
                                hide-details
                                outlined
                                prepend-icon="mdi-map-marker"
                                :disabled="!isEditActive"
                                class="mt-2"
                            />
                            <!-- email -->
                            <v-text-field 
                                v-model="medicalRepData.email"
                                label="Email"
                                dense
                                hide-details
                                outlined
                                prepend-icon="mdi-email"
                                :disabled="!isEditActive"
                                class="mt-2"
                            />

                            <!-- facebookProfileUrl -->
                            <v-text-field 
                                v-model="medicalRepData.facebookProfileUrl"
                                label="Facebook profile Url"
                                dense
                                hide-details
                                outlined
                                prepend-icon="mdi-facebook"
                                :disabled="!isEditActive"
                                class="my-2"
                            />

                            <bee-date-picker
                                v-model="medicalRepData.startingDate"
                                input-label="Hiring date"
                                elevation="2"
                                menu-picker
                                dense
                                hide-details
                                :input-props="{
                                    outlined: true,
                                    dense: true,
                                    prependIcon: 'mdi-calendar'
                                }"
                                :disabled="!isEditActive"
                                class="mt-2"
                            ></bee-date-picker>
                            <v-text-field
                                v-model="medicalRepData.username"
                                label="Username"
                                elevation="2"
                                dense
                                hide-details
                                outlined
                                prepend-icon="mdi-account"
                                :disabled="!isEditActive"
                                class="mt-2"
                            />

                            <v-text-field
                                v-model="medicalRepData.password"
                                label="Password"
                                elevation="2"
                                dense
                                hide-details
                                outlined
                                prepend-icon="mdi-eye"
                                :disabled="!isEditActive"
                                class="mt-2"
                            />
                        </v-col>
                        <!-- action -->
                        <v-col cols="12" class="pa-md-0">
                            <v-btn
                                v-if="isSuperAdmin || permissionsUser.MedicalReps"
                                :color="!isEditActive ? 'edit-color white--text' : 'success white--text'"
                                :loading="submitLoading"
                                type="submit"
                                height="35"
                                :width="!isEditActive ? '100%' : '70%'"
                            >
                                <v-icon size="22" class="me-1" >
                                    {{ !isEditActive ? 'mdi-pencil' : 'mdi-content-save-edit'}} 
                                </v-icon>
                                {{ !isEditActive ? 'Edit data' : 'Save'}}
                            </v-btn>
                            <v-btn
                            	v-if="isEditActive"
                                height="35"
                                width="27%"
                                class="ms-2"
                            	@click="isEditActive = false"
                            >
                                Cancel
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>

            <v-divider
            	v-if="$vuetify.breakpoint.mdAndUp"
            	vertical
            	:style="{ minHeight: `${$vuetify.breakpoint.height - 60}px` }"
            />
            
            <!-- tabs -->
            <v-col md="9" cols="12" class="ps-md-3">
                <div class="d-flex justify-space-between">
                    <div>
                        <!-- tabs -->
                        <v-tabs v-model="tab" show-arrows height="35">
                            <v-tab
                                class="text-capitalize "
                                :to="{ 
                                    name: 'medical-rep-personal-data', 
                                    params: { id: $route.params.id },
                                    query: { employeeType: userType.medicalRep }
                                }"
                            >
                                Work data
                            </v-tab>
                            <v-tab
                                class="text-capitalize "
                                :to="{ name: 'medical-rep-info-day-requests', params: { id: $route.params.id } }"
                            >
                                Day off requests
                            </v-tab>
                            <v-tab
                                class="text-capitalize "
                                :to="{ name: 'medical-samples', params: { id: $route.params.id } }"
                            >
                                samples
                            </v-tab>

                            <v-tab
                                v-if="isSuperAdmin || permissionsUser.MedicalReps"
                                class="text-capitalize "
                                :to="{ name: 'medical-rates', params: { id : $route.params.id } }"
                            >
                                Rates
                            </v-tab>
                            <v-tabs-slider color="primary"></v-tabs-slider>
                        </v-tabs>
                        <v-divider class="mb-2"/>
                    </div>
                    <v-spacer />
                    <!-- BACK -->
                    <v-btn
                        color="grey darken-2 white--text"
                        small
                        :to="{ 
                            name: 'staff', 
                            query: {
                                role: $route.query.role
                            } 
                        }"
                        class="mb-sm-4 mb-md-0 align-self-center"
                    >
                        <v-icon small class="me-1"> mdi-arrow-left-circle </v-icon>
                        Back
                    </v-btn>
                </div>
                <!-- child page -->
                <loader v-if="loading"/>
                <router-view v-else/>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { gender, genders, userType } from '@/helpers/enums'
import rules from '@/validation rules'
import moment from 'moment'
import Loader from '@/components/Loader.vue'

export default {
    name: 'MedicalRepInfo',
    
    components: {
        Loader
    },

    data() {
        return {
            // helpers
            rules,
            gender,
            genders,
            previewPhoto: null,
            userType,

            // data
            medicalRepData: {
                name: null,
                birthDate: null, 
                landlineNumber: null,
                phoneNumber: null,
                cityId: null,
                sectorId: null,
                address: null,
                visitPerDay: null,
                email: null,
                jobPosition: null,
                facebookProfileUrl: null, 
                startingDate: null,
                username: null,
                password: null,
                userType: userType.medicalRep,
                gender: gender.male,
                target: null,
                lineManagerId: null,
                regionIds: [],
                specialtyIds: [],
                roleId: null,
                teamId: []
            },
            medicalRepImagePath: null,
            medicalRepPhoto: null,
            tab: 0,
            loading: false,
            isEditActive: false,
            submitLoading: false,
        }
    },

    computed: {
        ...mapState({
            medicalReps: state => state.medicalreps.medicalreps,  
            cities: state => state.cities.cities,
            sectorsFiltered: state => state.sectors.sectorsFiltered,
            sectors: state => state.sectors.sectors,
            permissionsUser: state => state.auth.youPermissions,
            isSuperAdmin: state => state.auth.isSuperAdmin,
            teams: state => state.teams.teams
        }),

        ...mapGetters({
            getMedicalRepById: 'medicalreps/getMedicalRepById',
        })
    },

    methods: {
        submit() {
            if (!this.isEditActive) {
                this.isEditActive = true;
            } else if (this.$refs.form.validate()) {
                this.submitLoading = true;
                this.isEditActive = true;
                this.$store.dispatch('medicalreps/update', {
                    id: this.$route.params.id,
                    data: this.medicalRepData
                }).then(() => {
                    this.$store.dispatch('medicalreps/fetchAll', true);
                    this.isEditActive = false;
                    this.$eventBus.$emit('show-snackbar', 'Employee is edited', 'info');
                }).finally(() => {
                    this.submitLoading = false;
                })
            }
        },

        onImageChange() {
            if (this.medicalRepPhoto) {
                const reader = new FileReader();
                reader.readAsDataURL(this.medicalRepPhoto);
                reader.onload = e => { this.previewPhoto = e.target.result; }
            }
        },
    },

    created() {
        const initMedicalRepData = () => {
            const data = this.getMedicalRepById(this.$route.params.id)
            this.medicalRepData.name = data?.name;

            this.medicalRepData.birthDate =
                data.birthDate ? moment(data.birthDate).format('YYYY-MM-DD') : null;
            this.medicalRepData.email = data.email;
            this.medicalRepData.phoneNumber = data.phoneNumber;
            this.medicalRepData.landlineNumber = data.landlineNumber;
            this.medicalRepData.sectorId = data.sectorId;
            this.medicalRepData.address = data.address;
            this.medicalRepData.visitPerDay = data.visitPerDay; 
            this.medicalRepData.jobPosition = data.jobPosition;
            this.medicalRepData.facebookProfileUrl = data.facebookProfileUrl;
            this.medicalRepData.startingDate =
                data.startingDate ? moment(data.startingDate).format('YYYY-MM-DD') : null;

            this.medicalRepData.username = data.username;
            this.medicalRepData.userType = userType.medicalRep;
            this.medicalRepData.gender = data.gender;

            this.medicalRepData.target = data.target;

            this.medicalRepImagePath = data.imagePath;
            this.medicalRepData.lineManagerId = data.lineManager
            this.medicalRepData.regionIds = data.regionIds
            this.medicalRepData.specialtyIds = data.specialtyIds
            this.medicalRepData.roleId = this.getMedicalRepById(this.$route.params.id)?.roleId
            this.medicalRepData.teamId = this.getMedicalRepById(this.$route.params.id)?.teamId
        }

        if (this.medicalReps.length === 0) {
            this.loading = true
            this.$store.dispatch('medicalreps/fetchAll', true).then(() => {
                initMedicalRepData();
            }).finally(() => {
                this.loading = false
            })
        }
        else {
            initMedicalRepData();
        }
    },

    metaInfo: {
        title: 'Employee info',
        titleTemplate: '%s | Octopharma'
    }
}
</script>
<style scoped>
.form-info {
    position: sticky;
    top: 20px;
}
</style>