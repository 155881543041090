<template>
	<div class="mt-2">
		<v-row dense align="center">
			<!-- filter -->
			<v-col order-sm="0" order="1" md="3" sm="4" cols="12">
				<bee-date-input
					v-model="date"
					:label="$t('inputs.date')"
					dense
					:disabled="loading"
					input-format-order="DD-MM-YYYY"
					hide-details
					append-icon="mdi-menu-right"
					prepend-icon="mdi-menu-left"
					@click:append="
                        needToResetShownRows = true;
                        date = moment(date).add(1, 'day').format('YYYY-MM-DD');
                        getJournal(null, null);
                    "
					@click:prepend="
                        needToResetShownRows = true;
                        date = moment(date).subtract(1, 'day').format('YYYY-MM-DD')
                        getJournal(null, null);
                    "
				></bee-date-input>
			</v-col>
			<v-spacer/>

			<!-- remaining -->
			<v-col sm="auto" cols="12" class="text-end">
				<v-btn
					v-if="isSuperAdmin || permissionsUser.MaterialTransactions !== roleName.nothing"
					small
					:to="{ 
						name: 'supervisor-remaining-samples',
						query: { from: 'delivery' }
					}"
					color="light-blue white--text"
				>
					{{$t('JournalDeliveryOfSamples.remaining-samples')}}
				</v-btn>
			</v-col>
		</v-row>

		<!-- type tabs -->
		<v-tabs v-model="entityTab" height="40" @change="updateTableDueTab()">
			<v-tab
				v-for="item in types"
				:key="item.id"
				:disabled="loading"
			>{{item.name}}</v-tab>
		</v-tabs>
		<v-divider class="mb-2"/>

		<!-- deliver / receive tabs -->
		<v-tabs
			v-if="entityTab === 0"
			v-model="tab"
			height="30"
			active-class="secondary lighten-1 white--text overflow-hidden rounded-t"
			@change="needToResetShownRows = true; getJournal(null, null)"
		>
			<v-tab :disabled="loading">{{$t('JournalDeliveryOfSamples.deliver')}}</v-tab>
			<v-tab :disabled="loading">{{$t('JournalDeliveryOfSamples.receive')}}</v-tab>
			<v-tabs-slider color="secondary lighten-3"></v-tabs-slider>
		</v-tabs>

		<!-- table -->
     	<bee-handy-smart-table
			v-model="tableInputs"
			ref="table"
			:headers="headers"
			:items="[]"
			:show-add-row="permissionsUser.MaterialTransactions !== roleName.read"
			:loading="loading"
			:items-per-page="50"
			hide-default-footer 
			dense
			zebra
			:disable-input-fields="loading"
            fixed-header
            :height="$vuetify.breakpoint.height - ($vuetify.breakpoint.smAndUp ? 210 : 250)"
            :options-scroll.sync="scrollOptions"
            pagination-on-scroll
            pagination-on-scroll-server-items
			hide-inputs-details
            :server-items-length="serverItemsLength"
            :pagination-on-scroll-auto-reset-shown="false"
			@keypress.enter="addItem"
			@sheet-add-clicked="addItem"
            @pagination-on-scroll:reach-last-row="getJournal"
		>
			<template v-slot:item.accountId="{ item }">
				{{getAccount(item)}}
			</template>

            <!-- accounts -->
			<template v-slot:input.accountId="{ on, attr }">
				<v-autocomplete
					:items="allAccountsBrief.filter(c => c.id !== employeeId)"
					item-value="accountId"
					item-text="name"
					v-on="on"
					v-bind="attr"
				></v-autocomplete>
			</template>

			<!-- product name -->
			<template v-slot:input.materialId="{ on, attr }">
				<v-autocomplete
					:items="materials"
					item-value="id"
					item-text="name"
					v-on="on"
					v-bind="attr"
				></v-autocomplete>
			</template>
       	</bee-handy-smart-table>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { roleName } from '@/helpers/enums'
import moment from 'moment'
import rules from '@/validation rules'

export default {
	name: 'JournalDeliveryOfSamples',

    data () {
        return {
			tab: 0,
			entityTab: 0,
			roleName,
            loading: false,
            tableInputs: {},

			date: this.moment().format('YYYY-MM-DD'),
			types: [
                { id: 0, name: this.$t('tabs.staff') },
                { id: 1, name: this.$t('tabs.customers') },
                { id: 2, name: this.$t('tabs.external') }
            ],
			serverItemsLength: 0,
			scrollOptions: {},

			briefAccounts: [
                [], // for staff
                [], // for customers
                [], // for external
            ],
        }
    },
    computed: {
        ...mapState({
            supervisors: state => state.supervisors.supervisors,
            materials: state => state.materials.materials,
			isSuperAdmin: state => state.auth.isSuperAdmin,
			permissionsUser: state => state.auth.youPermissions,
			employeeId: state => state.auth.userData.employeeId
        }),

        ...mapGetters({
            getMaterialById: 'materials/getMaterialById',
            getSupervisorById: 'supervisors/getSupervisorById',
			getAccountById: 'accounts/getAccountById'
        }),

        headers () {
            return [
                {
                    text: this.$t('headers.account'),
                    name: 'accountId',
                    value: 'accountId',
					inputRules: rules.required,
                    type: 'string'
                },
                {
                    text: this.$t('headers.item'),
                    name: 'materialId',
                    value: 'materialId',
                    setValueExpr: val => this.getMaterialById(val)?.name,
					inputRules: rules.required,
                    type: 'string'
                },
                {
                    text: this.$t('headers.amount'),
                    name: 'amount',
                    value: 'amount',
					inputRules: rules.IntNumber,
                    type: 'number'
                },
				{
                    text: this.$t('headers.notes'),
                    name: 'description',
                    value: 'description',
                    type: 'string'
                },
				{
                    text: this.$t('headers.deliver-date'),
                    name: 'date',
                    value: 'date',
                    type: 'date',
                    width: "20%"
                }
            ]
        },

		allAccountsBrief() {
            return this.briefAccounts[this.entityTab];
        },

		currentUserData() {
            return this.tab === 0 && this.briefAccounts[0].length
                ? this.briefAccounts[0].find(c => c.id === this.employeeId) || {}
                : {};
        },
    },

    methods: {
		getAllAccount(isFirstLoad) {
            if (this.allAccountsBrief.length) return;

            this.loading = true;
            return this.$store.dispatch('accounts/fetchAllBrief', {
                type: this.entityTab,
            }).then((data) => {
                this.briefAccounts[this.entityTab].push(...data);
            }).finally(() => {
                if (!isFirstLoad) this.loading = false;
            })
        },

		updateTableDueTab() {
            this.$refs.table.resetShownRows();
            this.$refs.table.resetInputs({ date: this.moment().format('YYYY-MM-DD') });
            this.$router.replace({ name: 'journal-delivery-of-samples', query: { tab: this.entityTab } });
            this.updateJournal();
        },
		
		async updateJournal(isFirstLoad = false) {
            this.loading = true;
            return Promise.all([
                await this.getAllAccount(),
                this.getJournal(null, true),
            ]).finally(() => {
                if (!isFirstLoad) this.loading = false;
            });
        },

        addItem () {
			if (this.$refs.table.validate()) {
				this.loading = true
				var materials = {
					materialsAmount: {
						materialId: this.tableInputs.materialId,
						amount: this.tableInputs.amount	
					},
					fromAccountId: this.tab === 0 ? this.currentUserData.accountId : this.tableInputs.accountId,
					toAccountId: this.tab === 0 ? this.tableInputs.accountId : this.currentUserData.accountId,
					date: this.tableInputs.date,
					description: this.tableInputs.description
				}
				this.$store.dispatch('materialTransactions/createDeliverAndReceive', { materials }).then(async() => {
					this.needToResetShownRows = true;
					this.tableInputs.materialId = null;
					this.tableInputs.amount = null;
					await this.updateJournal();
				}).finally(() => {
					this.$refs.table.resetInputsFocus()
				})
			}
        },

		getJournal(newOptionsScroll, isFirstLoad = false) {
            let page = 1
            const { itemsPerPage } = this.scrollOptions;
            if (newOptionsScroll) page = newOptionsScroll.page || 1;
			if (this.needToResetShownRows) {
				this.needToResetShownRows = false;
				this.$refs.table.resetShownRows();
			}
            
            this.loading = true
			return this.$store.dispatch('materialTransactions/fetchStatement', {
                Date: this.date,
                Page: page,
                PerPage: itemsPerPage || 50,
                type: this.entityTab,
                WithVisitTransaction: true,
				SelectInAmount: this.entityTab === 0 && this.tab === 0,
				SelectOutAmount: this.entityTab === 0 && this.tab === 1,
            }).then(({ data, total }) => {
				const items = data.map(c => ({ 
                    ...c, 
                    amount: (c.in || c.out).amount,
	            	materialId: (c.in || c.out).materialId,
                    accountId: c.accountId,
                    notes: c.description,
                }));

                this.serverItemsLength = total;
                this.$refs.table.addScrollItems(items);
			}).finally(() => {
                if (!isFirstLoad) this.loading = false;
            })
		},

		getAccount(item) {
			const accountName = this.allAccountsBrief.find(c => c.accountId === (
				this.entityTab === 0 && this.tab === 1
					? item.fromAccountId
					: item.accountId
				)
			)
			return accountName ? accountName.name : '-'
		},

		moment,
    },

    mounted () {
        this.loading = true;
		this.needToResetShownRows = true;
		this.entityTab = +this.$route.query.tab || 0;
		this.$refs.table.resetInputs({ date: this.moment().format('YYYY-MM-DD') });

        Promise.all([
			!this.materials.length ? this.$store.dispatch('materials/fetchAll') : null,
			this.updateJournal(true),
        ]).finally(() => {
            this.loading = false
        })
    }
}
</script>
<style scoped>
</style>
