<template>
    <div>
        <v-form @submit.prevent="getMaterial()" ref="form">
            <v-row class="mt-1">
                <v-col cols="8" sm="6" md="3">
                    <v-autocomplete
                        v-model="employeeId"
                        :items="employees"
                        item-text="name"
                        item-value="id"
                        :label="$t('inputs.employees')"
                        outlined
                        hide-details="auto"
                        :rules="rules.required"
                        dense
                        @keypress.enter="getMaterial()"
                    />
                </v-col>
                <v-col>
                    <v-btn type="submit" color="success" dense small>
                        {{$t('search')}}
                    </v-btn>
                </v-col>
                <v-spacer />
                <v-col class="d-flex justify-end">
                    <v-btn
                        small
                        :to="$route.query.from === 'delivery' ? { name: 'journal-delivery-of-samples' } : { name: 'journal-items'}"
                        color="grey white--text"
                    >
                        <v-icon small class="me-1"> mdi-arrow-left-circle</v-icon>
                        Back
                    </v-btn>
                    <v-divider vertical class="mx-1" />
                    <v-btn
                        small
                        color="green lighten-1 white--text"
                        @click="ImportExcel"
                        class="mx-1"
                        :loading="loadingExcel"
                        :disabled="loadingExcel"
                    >
                        <v-icon> mdi-file-table-outline </v-icon>
                        Export Excel
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
        <bee-handy-table
            :headers="headers"
            :items="!loading ? summary : []"
            :items-per-page="20"
            :loading="loading"
            multi-sort
            loading-text="Loading... Please wait"
            class="elevation-3"
            dense
            zebra
            hide-default-footer
            :height="$vuetify.breakpoint.height - 100"
            pagination-on-scroll
        >
        </bee-handy-table>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import rules from '@/validation rules'

export default {
    data(){
        return {
            employeeId: null,
            loading: true,
            rules,
            employees: [],
            summary: [],
            loadingExcel: false
        }
    },

    mounted() {
        this.employeeId = this.userId
        var promises = [];
        if (this.$store.state.materials.materials.length === 0) {
            promises.push(this.$store.dispatch('materials/fetchAll'));
        }
        if (this.$route.query.supervisor && this.$store.state.supervisors.supervisors.length === 0) {
            promises.push(this.$store.dispatch('supervisors/fetchAll'));
        }

        Promise.all(promises).then(() => {
            this.$store.dispatch('accounts/fetchAllBrief', { type: 0 }).then((data) => {
                this.employees = data
            }).then(() => {
                this.getMaterial()
            })
        });
    },

    methods: {
        ImportExcel() {
            this.loadingExcel = true
            this.$store.dispatch('exporter/fetchTransactionSummaryExport', { id: this.employeeId }).finally(() => {
                this.loadingExcel = false
            })
        },
        
        getMaterial () {
            this.loading = true
            this.$store.dispatch('materialTransactions/fetchRemaining', { id: this.employeeId }).then((data) => {
                this.summary = data.map(c => ({ 
                    ...c,
                    name: this.getMaterialById(c.materialId)?.name
                })).sort(function(a, b) { if (a.name < b.name) { 
				    return -1
			    }; 
			    if (a.name > b.name) {
				    return 1 
			    }; 
			        return 0 
		        })
            })
                .finally(() => {
                    this.loading = false;
                });
        },
        dig(getterName, id, prop){
            var object = this[getterName](id);
            return object ? object[prop] : null;
        },

        getFullMaterialName(material) {
            if (material == null) return null;

            var details = [
                this.getMaterialFormById(material.materialFormId)?.name,
                material.dose,
                material.strength
            ];

            return `${material?.name} (${details.filter(c => c != null).join(' ')})`;
        },
    },

    watch: {
        menuOfStartDate (val) {
            val && setTimeout(() => (this.activePickerStartDate = 'YEAR'))
        },

        menuOfEndDate (val) {
            val && setTimeout(() => (this.activePickerEndDate = 'YEAR'))
        },
    },

    computed:{
        ...mapGetters({
            getMaterialById: 'materials/getMaterialById',
            getMaterialFormById: 'materialForms/getMaterialFormById',
        }),

        ...mapState({
            materials: state => state.materials.materials,
            materialForms: state => state.materialForms.materialForms,
            userId: state => state.auth.userData.employeeId
        }),

        headers() {
            return [
                {
                    text: this.$t('headers.material'),
                    value: 'in',
                    setValueExpr: val => this.getFullMaterialName(this.getMaterialById(val.materialId)) || 'N/A',
                    align: 'start',
                    class:'primary--text',
                    cellClass:'primary--text'
                },
                { 
                    text: this.$t('headers.remaining'),
                    value: 'remaining.amount',
                    align: 'start',
                },
            ]
        }
    },

}
</script>
