export const logo = require('@/assets/brands/golden-pharma.png');
export const drawerLogos = {
    normal: require('@/assets/brands/golden-pharma-white.png'),
    mini: require('@/assets/brands/golden-pharma-white.png')
};

export const colors = {
    primary: '#253c99', // #0b5b6f
    primaryLight: '#b2c1ff', // #a0ecff
    secondary: '#f57f20', // #f57f20
    lightBlue: '#409dd6', // #0996b9
}