import * as types from '../mutation-types';
export default {
    namespaced: true,
    state: {
        accounts: []
    },

    actions: {
        fetchAll({ commit }, { regionIds, name, type, nonDeleted, IncludeContactInfo, includeAddresses, perPage, page }) {
            var urlSearchParams = new URLSearchParams();
            var params = { name, type, nonDeleted, IncludeContactInfo, includeAddresses, perPage, page };
            Object.keys(params).forEach(param => {
                if (params[param] !== null && params[param] !== undefined) {
                    urlSearchParams.append(param, params[param]);
                } 
            })
            regionIds.forEach(id => {
                urlSearchParams.append("regionIds", id);
            })
            return window.axios.get('/account', {
                params: urlSearchParams 
            }).then((response) => {
                commit(types.STORE_ACCOUNTS, response.data)
                return {
                    data: response.data,
                    total: parseInt(response.headers['x-pagination-total-count'])
                }
            })
        },

        fetchAllBrief({ commit }, { type }) {
            return window.axios('account/brief', { params: { type } }).then(({ data }) => {
                return data
            })
        },

        create({ commit }, { data }) {
            return window.axios.post('/account', data).then(({ data }) => {
                return data
            })
        },

        update({ commit }, { id, data }) {
            return window.axios.put(`/account/${id}`, data).then(({ data }) => {
                return data
            })
        },

        delete({ commit }, { id }) {
            return window.axios.delete(`/account/${id}`)
        }
    },
    mutations: {
        [types.STORE_ACCOUNTS] (state, accounts) {
            state.accounts = accounts
        }
    },

    getters: {
        getAccountById: state => id => state.accounts.find(c => c.accountId === id)
    }
}